import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Layers } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CompanyLayersService {

  constructor(public http: HttpClient) {
  }

  getLayers(): Promise<Layers[]> {
    return this.http.get<Layers[]>('/api/v1/layers/').toPromise();
  }
  getCompanyLayers(userCompanyId: number): Promise<Layers[]> {
    return this.http.get<Layers[]>('/api/v1/layers/' + userCompanyId).toPromise();
  }

  addLayers(item: Layers): Promise<Layers> {
    return this.http.post<Layers>('/api/v1/layers/', item).toPromise();
  }

  updateLayer(id: number, item: Layers): Promise<Layers> {
    return this.http.put<Layers>('/api/v1/layers/' + id, item).toPromise();
  }

  deleteLayer(id: number): Promise<any> {
    return this.http.delete(`/api/v1/layers/` + id).toPromise();
  }
}
