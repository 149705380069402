import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {

  infoMsg: string;
  additionalData: string[];

  constructor(private activeModal: NgbActiveModal) { }

  onOk() {
    this.activeModal.close();
  }
}
