import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageEnlargeService {
    public enlargeImage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    enlarge(image: string) {
        this.enlargeImage.next(image);
    }

    close() {
        this.enlargeImage.next(null);
    }
}
