import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Upvotes } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class UpvotesService {

  constructor(private http: HttpClient) {
  }

  upvoteGeometry(geometryId: number, comment?: string): Promise<void> {
    return this.http.post<void>(`api/v1/upvotes/${geometryId}`, {geometryId: geometryId, comment: comment || null}).toPromise();
  }

  getGeometryUpvotes(geometryId: number): Promise<Upvotes[]> {
    return this.http.get<Upvotes[]>('api/v1/upvotes/' + geometryId).toPromise();
  }

}
