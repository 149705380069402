import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

// ngx-cookieconsent config
export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#151b1e',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#63c2de',
      text: '#ffffff',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    link: 'Learn more',
    href: 'https://cookiesandyou.com'
  }
};
