import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, Location } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateService, TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizeRouterModule, LocalizeParser, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';

import { AppComponent } from './app.component';

import { ClassifierModalComponent, LayerStyleComponent, LayerFieldsComponent, LayerFilterComponent } from '@pgis/views/classifiers/classifier-modal';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './cookie.config';
import { ColorPickerModule } from 'ngx-color-picker';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// Import routing module
import { AppRoutingModule, routes } from './app.routing';

import { SharedModule } from '@pgis/shared';
import { CoreModule } from '@pgis/core';

import { JwtInterceptor, LoaderInterceptor } from '@pgis/interceptors';

// Import containers
import {
  FullLayoutComponent,
  SimpleLayoutComponent
} from './containers';

const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent
];

// Import components
import {
  AppAsideComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppBreadcrumbsComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
  AuthComponent,
  InfoTabComponent,
  BaseLayersTabComponent,
  ObjectFilesTabComponent,
  MapControlsTabComponent,
  ObjectFilterComponent,
  ObjectLegendComponent,
  PrintLegendComponent
} from './components';

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
  AuthComponent,
  InfoTabComponent,
  BaseLayersTabComponent,
  ObjectFilesTabComponent,
  MapControlsTabComponent,
  ObjectFilterComponent,
  ObjectLegendComponent,
  PrintLegendComponent
];

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';
import { InfoDialogService } from '@pgis/services/info-dialog.service';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { CustomIconComponent } from './views/classifiers/classifier-modal/layer-style/custom-icon/custom-icon.component';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    LocalizeRouterModule.forRoot(routes,
      {
        parser: {
          provide: LocalizeParser,
          useFactory: (translate, location, settings, http) =>
            new ManualParserLoader(translate, location, settings, ['lv', 'en', 'ru'], 'MY_PGIS'),
          deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
        }
      }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    CoreModule.forRoot(),
    GooglePlaceModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ColorPickerModule,
    PdfViewerModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    ClassifierModalComponent,
    LayerStyleComponent,
    LayerFieldsComponent,
    LayerFilterComponent,
    HelpDialogComponent,
    CustomIconComponent
  ],
  entryComponents: [
    ClassifierModalComponent,
    PrintLegendComponent,
    HelpDialogComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    ConfirmDialogService,
    InfoDialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
