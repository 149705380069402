import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { GeomStyle, Classifier } from '@pgis/shared/models';
import FontSymbol from 'ol-ext/style/FontSymbol';
import FontAwesomeDef from 'ol-ext/style/FontAwesomeDef';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedToastrService, ClassifiersService, AuthenticationService } from '@pgis/core/services';
import * as _ from 'lodash';

@Component({
  selector: 'pgis-layer-style',
  templateUrl: './layer-style.component.html',
  styleUrls: ['./layer-style.component.scss']
})
export class LayerStyleComponent implements OnInit, OnChanges {
  @ViewChild('selectedIcon')
  selectedIcon;

  @ViewChild('objectRules')
  objectRules;

  @Input()
  geomStyle: GeomStyle;

  @Input()
  layerFields: string[];

  @Input()
  classifier: Classifier;

  @Output()
  onToggleAdvancedSettings: EventEmitter<boolean> = new EventEmitter<boolean>();

  iconArray: any = [];
  iconArrayRows: any = [];
  advancedSettings = false;
  selectedRuleIndex: any;
  fs: any = new FontSymbol(FontAwesomeDef);
  fixedDefs: any = [];

  ruleOptions: any = [];
  selectizeInitialized: boolean;
  ruleValue: string;
  ruleKey: any;

  activeIconTab: string;
  activeTab: string = 'cl';
  generateRuleLabel: string;
  deleteRuleLabel: string;

  selectizeConfig: any = {
    labelField: 'label',
    valueField: 'label',
    dropdownDirection: 'down',
    highlight: true,
    addPrecedence: true,
    maxItems: 1,
    searchField: 'label',
    plugins: ['dropdown_direction']
  };

  constructor(private confirmDialogService: ConfirmDialogService,
    private translateService: TranslateService,
    private toastr: LocalizedToastrService,
    private classifiersService: ClassifiersService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    // FontAwesome objects. Remove object keys and create array of objects for *ngFor.
    const glyphKeys = Object.keys(this.fs.defs.glyphs);
    for (const glyph of glyphKeys) {
       this.fixedDefs.push(this.fs.defs.glyphs[glyph]);
    }
    if (this.layerFields) {
      this.layerFields.forEach(layerField => {
        this.ruleOptions.push({ label: layerField['name'] });
      });
      this.selectizeInitialized = true;
    }
    //Checking if rule name follows the 'data.field_name=value' formula and setting rule key/value
    if (this.geomStyle.rule.includes('.') && this.geomStyle.rule.includes('=')) {
      let ruleFieldName = this.geomStyle.rule.substring(this.geomStyle.rule.indexOf('.') + 1);   //Removing 'data.'
      ruleFieldName = ruleFieldName.split('=').slice(0, -1).join('=');                           //Removing '=value'
      this.ruleKey = ruleFieldName || '';
    } else {
      this.ruleKey = this.geomStyle.rule.substring(this.geomStyle.rule.indexOf('.') + 1) || '';
    }
    this.ruleValue = this.geomStyle.rule.split('=').pop() || '';

    if (this.geomStyle.labelRules[0]['rule'] !== 'Default') {  //Setting Default style as first in the list
      const defaultRule = this.geomStyle.labelRules.find(gl => gl.rule === 'Default');
      this.geomStyle.labelRules = this.geomStyle.labelRules.filter(l => l.rule !== 'Default');
      this.geomStyle.labelRules.unshift(defaultRule);
    }

    if (!this.selectedRuleIndex) {
      this.selectedRuleIndex = this.geomStyle.labelRules.findIndex(r => r.rule === 'Default');
    }
    this.activeIconTab = this.authenticationService.userHavePermission('Admin') && this.geomStyle.customPointIcon ? 'custom-icon-tab' : 'fontAwesome-tab';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layerFields && !changes.layerFields.firstChange) {
      this.selectizeInitialized = false;   //updating fields selectize on layer fields change
      setTimeout(() => {
        const k = [];
        changes.layerFields.currentValue.forEach(layerField => {
          k.push({ label: layerField['name'] });
        });
        this.ruleOptions = [...k];
        this.selectizeInitialized = true;
      });
    }
  }

  onRuleAdd() {
    this.saveCurrentStyles();
    this.ruleKey = '';
    this.ruleValue = '';
    const objToPush = {
      fillColor: this.geomStyle.fillColor,
      lineColor: this.geomStyle.lineColor,
      pointColor: this.geomStyle.pointColor,
      pointBgColor: this.geomStyle.pointBgColor,
      fillPatternColor: this.geomStyle.fillPatternColor,
      linePatternColor: this.geomStyle.linePatternColor,
      labelSize: this.geomStyle.labelSize,
      labelColor: this.geomStyle.labelColor,
      labelFont: this.geomStyle.labelFont,
      labelEnabled: this.geomStyle.labelEnabled,
      lineWidth: this.geomStyle.lineWidth,
      lineStyle: this.geomStyle.lineStyle,
      objLabel: this.geomStyle.objLabel,
      rule: 'data.' + this.ruleKey + '=' + this.ruleValue,
      pointIcon: this.geomStyle.pointIcon || '',
      pointSize: this.geomStyle.pointSize,
      pointForm: this.geomStyle.pointForm,
      pointRadius: this.geomStyle.pointRadius,
      pointRotation: this.geomStyle.pointRotation,
      pointGradient: this.geomStyle.pointGradient,
      customPointIcon: this.geomStyle.customPointIcon,
      fillPattern: this.geomStyle.fillPattern,
      strokePattern: this.geomStyle.strokePattern
    };
    this.geomStyle.labelRules.push(objToPush);
    this.selectedRuleIndex = this.geomStyle.labelRules.length - 1;    //newly added labelRule
    this.fillCurrentStyle(this.selectedRuleIndex);
  }

  toggleAdvancedSettings(formSubmitted?) {
    this.advancedSettings = !this.advancedSettings;
    if (formSubmitted) {
      setTimeout(() => {
        this.objectRules.nativeElement.scrollIntoView({ block: 'center' });
      }, 100);
    }
    this.onToggleAdvancedSettings.next(this.advancedSettings);
  }

  onRuleDel() {
    if (!this.selectedRuleIndex) {
      return;
    }
    if (this.geomStyle.labelRules[this.selectedRuleIndex].rule === 'Default') {
      this.toastr.error('CLASSIFIERS.CANNOT_DELETE_DEFAULT');
      return;
    }
    this.geomStyle.labelRules.splice(this.selectedRuleIndex, 1);
    this.selectedRuleIndex--;
    this.fillCurrentStyle(this.selectedRuleIndex);
  }

  onRuleChange(data, ruleType) {
    if (this.geomStyle.labelRules[this.selectedRuleIndex].rule !== 'Default') {
      this.geomStyle.labelRules[this.selectedRuleIndex].rule = 'data.' + (this.ruleKey || '') + '=' + (this.ruleValue || '');
    }
  }

  onLabelListChange(previousRule, newRule) {
    //Because ngModelChange is before ngModel, this.selectedRuleIndex is set to previous rule index, until function has ended
    if (!this.geomStyle.labelRules[newRule]) {
      return;
    }
    this.saveCurrentStyles();
    this.fillCurrentStyle(newRule);
    if (this.activeTab === 'is') {
      this.activeIconTab = this.authenticationService.userHavePermission('Admin') && this.geomStyle.customPointIcon ? 'custom-icon-tab' : 'fontAwesome-tab';
      this.scrollToTarget();
    }
  }

  onRuleGen() {
    if (!this.ruleKey) {
      this.toastr.error('GENERATE_RULEKEY_ERROR');
      return;
    }
    if (!this.classifier) {
      return;
    }
    this.confirmDialogService.showConfirmationDialog(this.translateService.instant('GENERATE_WARNING')).then((goGenerate: boolean) => {
      if (goGenerate) {
        this.classifiersService.generateRandomStyle(this.classifier.id, this.ruleKey).then(res => {
          // Server returns JSON string with capital first letter keys ('FillColor'). Need to convert those to lower case ('fillColor').
          // Iterating through an array of Label Rule objects and lowercasing their keys before adding to our geomStyle.
          const newRules = [];
          for (const obj of JSON.parse(res)['LabelRules']) {
            newRules.push(_.transform(obj, function (result, val, key) {
              result[key.charAt(0).toLowerCase() + key.slice(1)] = val;
            }));
          }
          // Adding newly generated Label Rules to our geomStyle.
          this.geomStyle.labelRules = newRules;
          // Adding missing Default Label Rule.
          const defaultStyle = new GeomStyle;
          // No need for labelRules in Default rule (LabelRules are only for main GeomStyle).
          delete defaultStyle.labelRules;
          defaultStyle.rule = 'Default';
          this.geomStyle.labelRules.push(defaultStyle);
          // Auto select Default rule after retrieving new Label Rules.
          this.selectedRuleIndex = this.geomStyle.labelRules.findIndex(r => r.rule === 'Default');
          // this.onLabelListChange(this.selectedRuleIndex);
        });
      }
    });
  }

  onCustomIconDelete(ev) {
    if (this.geomStyle.customPointIcon == ev) {        //Remove deleted icon from current classifier
      this.geomStyle.customPointIcon = '';
    }
    for (let labelRule of this.geomStyle.labelRules) {
      if (labelRule.customPointIcon == ev) {
        labelRule.customPointIcon = '';
      }
    }
  }

  onTabChange(tabName) {
    this.activeTab = tabName;
    if (tabName && tabName === 'is') { //Icon style tab
      this.activeIconTab = this.authenticationService.userHavePermission('Admin') && this.geomStyle.customPointIcon ? 'custom-icon-tab' : 'fontAwesome-tab';
      this.scrollToTarget();
    }
  }

  scrollToTarget() {
    setTimeout(() => {
      if (this.selectedIcon) {
        this.selectedIcon.nativeElement.scrollIntoView({ block: 'center' });
      }
    }, 200);
  }

  saveCurrentStyles() {  //saving current style values to respective label rule
    this.geomStyle.labelRules[this.selectedRuleIndex].fillColor = this.geomStyle.fillColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].lineColor = this.geomStyle.lineColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointColor = this.geomStyle.pointColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointBgColor = this.geomStyle.pointBgColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].fillPatternColor = this.geomStyle.fillPatternColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].linePatternColor = this.geomStyle.linePatternColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].labelSize = this.geomStyle.labelSize;
    this.geomStyle.labelRules[this.selectedRuleIndex].labelColor = this.geomStyle.labelColor;
    this.geomStyle.labelRules[this.selectedRuleIndex].labelFont = this.geomStyle.labelFont;
    this.geomStyle.labelRules[this.selectedRuleIndex].labelEnabled = this.geomStyle.labelEnabled;
    this.geomStyle.labelRules[this.selectedRuleIndex].lineWidth = this.geomStyle.lineWidth;
    this.geomStyle.labelRules[this.selectedRuleIndex].lineStyle = this.geomStyle.lineStyle;
    this.geomStyle.labelRules[this.selectedRuleIndex].objLabel = this.geomStyle.objLabel;
    if (this.geomStyle.labelRules[this.selectedRuleIndex].rule !== 'Default') {
      this.geomStyle.labelRules[this.selectedRuleIndex].rule = 'data.' + (this.ruleKey || '') + '=' + (this.ruleValue || '');
    }
    this.geomStyle.labelRules[this.selectedRuleIndex].pointIcon = this.geomStyle.pointIcon;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointSize = this.geomStyle.pointSize;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointForm = this.geomStyle.pointForm;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointRadius = this.geomStyle.pointRadius;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointRotation = this.geomStyle.pointRotation;
    this.geomStyle.labelRules[this.selectedRuleIndex].pointGradient = this.geomStyle.pointGradient;
    this.geomStyle.labelRules[this.selectedRuleIndex].fillPattern = this.geomStyle.fillPattern;
    this.geomStyle.labelRules[this.selectedRuleIndex].strokePattern = this.geomStyle.strokePattern;
    this.geomStyle.labelRules[this.selectedRuleIndex].customPointIcon = this.geomStyle.customPointIcon;
  }

  fillCurrentStyle(value) {  //Filling layer style with values from respective label rule
    this.geomStyle.fillColor = this.geomStyle.labelRules[value].fillColor;
    this.geomStyle.lineColor = this.geomStyle.labelRules[value].lineColor;
    this.geomStyle.pointColor = this.geomStyle.labelRules[value].pointColor;
    this.geomStyle.pointBgColor = this.geomStyle.labelRules[value].pointBgColor;
    this.geomStyle.fillPatternColor = this.geomStyle.labelRules[value].fillPatternColor;
    this.geomStyle.linePatternColor = this.geomStyle.labelRules[value].linePatternColor;
    this.geomStyle.labelSize = this.geomStyle.labelRules[value].labelSize;
    this.geomStyle.labelColor = this.geomStyle.labelRules[value].labelColor;
    this.geomStyle.labelFont = this.geomStyle.labelRules[value].labelFont;
    this.geomStyle.labelEnabled = this.geomStyle.labelRules[value].labelEnabled;
    this.geomStyle.lineWidth = this.geomStyle.labelRules[value].lineWidth;
    this.geomStyle.lineStyle = this.geomStyle.labelRules[value].lineStyle;
    this.geomStyle.objLabel = this.geomStyle.labelRules[value].objLabel;
    this.geomStyle.rule = this.geomStyle.labelRules[value].rule;

    if (this.geomStyle.rule.includes('.') && this.geomStyle.rule.includes('=')) {
      let ruleFieldName = this.geomStyle.rule.substring(this.geomStyle.rule.indexOf('.') + 1);
      ruleFieldName = ruleFieldName.split('=').slice(0, -1).join('=');
      this.ruleKey = ruleFieldName || '';
    } else {
      this.ruleKey = this.geomStyle.rule.substring(this.geomStyle.rule.indexOf('.') + 1) || '';
    }

    this.ruleValue = this.geomStyle.rule.split('=').pop() || '';
    this.geomStyle.pointIcon = this.geomStyle.labelRules[value].pointIcon;
    this.geomStyle.pointSize = this.geomStyle.labelRules[value].pointSize;
    this.geomStyle.pointForm = this.geomStyle.labelRules[value].pointForm;
    this.geomStyle.pointRadius = this.geomStyle.labelRules[value].pointRadius;
    this.geomStyle.pointRotation = this.geomStyle.labelRules[value].pointRotation;
    this.geomStyle.pointGradient = this.geomStyle.labelRules[value].pointGradient;
    this.geomStyle.fillPattern = this.geomStyle.labelRules[value].fillPattern;
    this.geomStyle.strokePattern = this.geomStyle.labelRules[value].strokePattern;
    this.geomStyle.customPointIcon = this.geomStyle.labelRules[value].customPointIcon;
  }

  setTranslationTooltips() {
    this.deleteRuleLabel = this.translateService.instant('MAP.POINT');
    this.generateRuleLabel = this.translateService.instant('GENERATE');
  }
}
