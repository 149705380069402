import { Injectable, ComponentFactoryResolver, Injector, Renderer2, RendererFactory2, ElementRef } from '@angular/core';
import { MapPopupComponent } from '@pgis/views/dashboard/map-popup/map-popup.component';

@Injectable({
    providedIn: 'root'
})
export class MapPopupService {

    private renderer: Renderer2;
    private popupContent: ElementRef;

    constructor(private resolver: ComponentFactoryResolver,
        private injector: Injector,
        rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    async getPopupContent(props: any, popupElement: HTMLElement): Promise<void> {
        const factory = this.resolver.resolveComponentFactory(MapPopupComponent);
        const component = factory.create(this.injector);
        component.instance.props = props;
        component.changeDetectorRef.detectChanges();

        if (this.popupContent) {
            this.renderer.removeChild(popupElement, this.popupContent);
        }

        this.popupContent = component.location.nativeElement;
        this.renderer.appendChild(popupElement, this.popupContent);
    }
}
