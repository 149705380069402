import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileFilesService {

  constructor(private http: HttpClient) {
  }

  importImageZip(formFile: FormData): Promise<any> {
    return this.http.post(`/api/v1/profile-files/import-zip`, formFile).toPromise();
  }

  getCompanyZipFiles(companyId: number): Promise<any> {
    return this.http.get('/api/v1/profile-files/company-zips/' + companyId).toPromise();
  }

  downloadZip(zipFile: string): Promise<any> {
    return this.http.post('api/v1/profile-files/download', { name: zipFile }, { responseType: 'blob' }).toPromise().then(res => {
      saveAs(res, zipFile);
    });
  }

  deleteZip(zipFile: string): Promise<any> {
    return this.http.delete('api/v1/profile-files/delete', { params: { name: zipFile } }).toPromise();
  }

  importCompanyLogo(formFile: FormData): Promise<any> {
    return this.http.post(`api/v1/profile-files/import-logo`, formFile).toPromise();
  }

  loadLogo(companyId: number): Promise<any> {
    return this.http.get('api/v1/profile-files/load-logo/' + companyId).toPromise();
  }

  deleteLogo(companyId: number): Promise<any> {
    return this.http.delete('api/v1/profile-files/delete-logo/' + companyId).toPromise();
  }

  uploadCustomIcons(formData: FormData): Promise<void> {
    return this.http.post<void>(`/api/v1/profile-files/custom-icons`, formData).toPromise();
  }

  getAllCustomIconUrls(): Promise<string[]> {
    return this.http.get<string[]>(`/api/v1/profile-files/custom-icons`).toPromise();
  }

  getCustomIcon(companyId: number, iconName: string): Observable<Blob> {
    return this.http.get<any>(`/api/v1/profile-files/custom-icons/${companyId}/${iconName}`, { responseType: 'blob' as 'json' });
  }

  deleteCustomIcon(iconName: string): Promise<any> {
    return this.http.delete('api/v1/profile-files/custom-icons', { params: { iconName: iconName } }).toPromise();
  }
}
