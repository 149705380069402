import { Injectable, Injector, ComponentFactoryResolver } from '@angular/core';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';
import { GeometriesService } from '@pgis/core/services/geometries.service';
import { PrintInfo } from '@pgis/shared/models/print-info.model';
import { Feature, Classifier } from '@pgis/shared';
import { MapService } from '@pgis/core';
import { PrintLegendComponent } from './/object-legend/print-legend.component';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private profileFilesService: ProfileFilesService,
    private geometriesService: GeometriesService,
    private mapService: MapService,
    private injector: Injector,
  private resolver: ComponentFactoryResolver) { }

  getPrintoutContent(printInfo: PrintInfo, currentCompanyId: number, featureIds: number[], canvas: any, objectData: Classifier[]): Promise<string> {
    const LOGOPlaceholder = '{{ LOGO }}';
    const dataTablesPlaceholder = '{{ DATA_TABLES }}';
    const legendPlaceholder = '{{ LEGEND }}';

    let printDoc = '<html>' +
      '<head>' +
      '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">' +
      '<style type="text/css" media="print">' +
      '.center { display: block; margin-left: auto; margin-right: auto; }' +
      '.companyLogo { display: block; height: 100px }' +
      '.wrapper { width: 75%; }' +
      '.headerTable { margin-bottom: 10px; width: 100%; }' +
      '.titleText { text-align: left; font-size: 20px; word-wrap: break-word; padding-right: 20px; }' +
      '.legend-style { display: inline-block;}' +
      '.point-legend { font: normal 1rem FontAwesome; cursor: default;}' +
      '.font-break-word { word-break: break-all;}' +
      '.mt-sm{margin-top: 8px;}' +
      '.grid-div {display: grid;}' +
      '.bold {font-weight: bold;}' +
      '.polygon { width: 20px; height: 20px;}' +
      '.line { width: 20px; height: 7px; margin-bottom: 3px; margin-left: 2px;}' +
      '.no-border { border-width: 0;}' +
      '.legend-border { border-width: 2px;}' +
      '.classifier-name { margin-bottom: 5px; font-size: 18px }' +
      '.mr-md { margin-right: 15px;}' +
      '.mb-lg { margin-bottom: 20px;}' +
      '.fieldset-settings { margin-bottom: 10px; -webkit-print-color-adjust: exact; }' +
      '.full-width {width: 100%;}' +
      '.footer { margin-top: 10px; }' +
      '.custom-icon {height: 20px;width: 20px;white-space: nowrap;text-align: center;}' +
      '.custom-size{max-width: 20px !important; max-height: 20px !important;}' +
      '.helper { display: inline-block; height: 100%; vertical-align: middle;}' +
      '</style>' +
      '</head>' +
      '<body onload="window.print();window.close()">' +
      '<div class="wrapper full-width">' +
      '<table class="headerTable">' +
      '<tr>' +
      LOGOPlaceholder +
      '<td><p class="titleText">' + (printInfo.header || '') + '</p></td>' +
      '</tr>' +
      '</table>' +
      '<main>' +
      '<img src="' +
      canvas.toDataURL('image/png') +
      '" class="center"/>' +
      '</main>' +
      legendPlaceholder +
      dataTablesPlaceholder +
      '<footer class="footer">' +
      (printInfo.footer || '') +
      '</footer>' +
      '</div>' +
      '</body>' +
      '</html>';

    const profileLogoPromise: Promise<any> = this.profileFilesService.loadLogo(currentCompanyId);
    let featureDataPromise: Promise<Feature[]>;

    if (printInfo.includeDataTables) {
      const extent = this.mapService.map.getView().calculateExtent(this.mapService.map.getSize());
      const zoom = this.mapService.map.getView().getZoom();
      const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];

      featureDataPromise = this.geometriesService.getFeatureData(extent, selectedLayers, zoom);
    } else {
      featureDataPromise = Promise.resolve([]);
    }

    const factory = this.resolver.resolveComponentFactory(PrintLegendComponent);
    const comp = factory.create(this.injector);
    comp.instance.objectData = objectData;
    comp.changeDetectorRef.detectChanges();

    const qwe = comp.location.nativeElement;

    return featureDataPromise.then(features => {
      printDoc = printInfo.includeDataTables && features.length > 0
        ? printDoc.replace(dataTablesPlaceholder, this.getDataTablesMarkup(features))
        : printDoc.replace(dataTablesPlaceholder, '');

      return profileLogoPromise.then(logo => {
        printDoc = logo
          ? printDoc.replace(LOGOPlaceholder, '<td width="10px"><img src="' + logo.logoImage + '" class="companyLogo"></td>')
          : printDoc.replace(LOGOPlaceholder, '');

        printDoc = qwe.innerHTML ?
          printDoc.replace(legendPlaceholder, qwe.innerHTML)
            : printDoc.replace(legendPlaceholder, '');

        return printDoc;
      }).catch((err) => {
        printDoc = printDoc
          .replace(LOGOPlaceholder, '');

        printDoc = qwe.innerHTML ?
          printDoc.replace(legendPlaceholder, qwe.innerHTML)
          : printDoc.replace(legendPlaceholder, '');
        return printDoc;
      });

    });
  }

  getDataTablesMarkup(features: Feature[]): string {
    this.geometriesService.addColumnsFromDataJson(features);
    
    const groupedFeatures = features.reduce((groups, current: Feature) => {
      const classId = current['classId'];
      groups[classId] = groups[classId] || [];
      groups[classId].push(current);
      return groups;
    }, {});

    let tablesMarkup = '';
    Object.keys(groupedFeatures).forEach(classId => {
      tablesMarkup += '<br/><table border="1" cellspacing="0" cellpadding="10">';
      tablesMarkup += '<thead><tr>';
      tablesMarkup += '<th>Id</th>';
      tablesMarkup += '<th>Nosaukums</th>';
      tablesMarkup += '<th>Apraksts</th>';
      const firstElement = groupedFeatures[classId][0];
      if (firstElement) {
        tablesMarkup += firstElement.dataArr.map(data => data.key ? `<th>${data.key}</th>` : `<th>&nbsp;</th>`).join('');
      }
      tablesMarkup += '</tr></thead><tbody>';
      groupedFeatures[classId].forEach((feature: Feature) => {
        const zz = feature.dataArr.map(data => `<td>${data.value}</td>`);
        tablesMarkup += '<tr>';
        tablesMarkup += `<td>${feature.id}</td>`;
        tablesMarkup += `<td>${feature.name}</td>`;
        tablesMarkup += `<td>${feature.description}</td>`;
        tablesMarkup += feature.dataArr.map(data => `<td>${data.value}</td>`).join('');
        tablesMarkup += '</tr>';
      });
      tablesMarkup += '</tbody></table>';
    });

    return tablesMarkup;
  }
}
