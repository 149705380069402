export class Layers {
  public id: number;
  public name: string;
  public layerType: string;
  public sourceType: string;
  public sourceJson: string;
  public active: boolean;
  public added: string;
  public group: string;
  public overlayOrder: number;
  public companyId: number;
  public overlay: boolean;
  public zipCreated: boolean;
  public isPublic: boolean;
}
