import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Comment } from '@pgis/shared';

@Injectable({ providedIn: 'root' })
export class CommentsService {
    constructor(private httpClient: HttpClient) { }

    addComment(objectId: number, comment: Comment): Promise<Comment> {
        return this.httpClient.post<Comment>(`/api/v1/geoms/${objectId}/comment`, comment).toPromise();
    }

    deleteComment(objectId: number, id: number): Promise<void> {
        return this.httpClient.delete<void>(`/api/v1/geoms/${objectId}/comment/${id}`).toPromise();
    }
}
