import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Proj from 'ol/proj';
import { MapService } from '@pgis/core/services';
import { MapGeolocationService } from '@pgis/core/services/map/map.geolocation.service';

@Component({
  templateUrl: './vzd-search-dialog.component.html',
  styleUrls: ['./vzd-search-dialog.component.scss']
})
export class VzdSearchDialogComponent implements OnInit {

  @Input()
  searchString: string;

  @Input()
  searchResult;

  resultPar: any;
  addressData = [];

   constructor(private activeModal: NgbActiveModal,
     private mapService: MapService,
     private geolocationService: MapGeolocationService) {
  }

  ngOnInit() {
     this.addressData = this.searchResult.results;
  }

  onRowClick(data) {
    if (data && data.geometry) {
      this.activeModal.close(data.attributes.STD);
      const coordinates = Proj.transform([data.geometry.x, data.geometry.y], 'EPSG:3059', 'EPSG:3857');
      this.geolocationService.setVZDPoint(coordinates, true);
      this.mapService.map.getView().setCenter(coordinates);
      this.mapService.map.getView().setZoom(15);
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
