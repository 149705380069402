import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Companies } from '@pgis/shared/models';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(public http: HttpClient) {
  }

  getCompany(companyId: number): Promise<Companies> {
    return this.http.get<Companies>('/api/v1/companies/' + companyId).toPromise();
  }

  updateCompany(companyId: number, item: Companies): Promise<Companies> {
    return this.http.put<Companies>('/api/v1/companies/' + companyId, item).toPromise();
  }
}
