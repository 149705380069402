import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'groupByFilter'
})
export class GroupByPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
    this.translateService = translateService;
  }
  transform(collection: any[], property: string): any[] {
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      if (!current[property] && !previous[this.translateService.instant('MAP.GROUPLESS_LAYERS')]) {
        previous[this.translateService.instant('MAP.GROUPLESS_LAYERS')] = [current];
      }
      else if (!current[property] && previous[this.translateService.instant('MAP.GROUPLESS_LAYERS')]) {
        previous[this.translateService.instant('MAP.GROUPLESS_LAYERS')].push(current);
      }
      else if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});
    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key], id: this.convertToSlug(key) + Math.floor(Math.random() * 100000) }));
  }

  private convertToSlug(text: string): string {
      return text
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
          ;
  }
}
