import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS.js';
import XYZ from 'ol/source/XYZ';
import * as Proj from 'ol/proj';
import * as Control from 'ol/control';
import olPolygon from 'ol/geom/Polygon';
import olLineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import olSelectInteraction from 'ol/interaction/Select';
import WKT from 'ol/format/WKT';
import olFeature from 'ol/Feature';
import Style from 'ol/style/Style';
import { Circle as CircleStyle } from 'ol/style.js';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import BingMaps from 'ol/source/BingMaps';
import TileWMS from 'ol/source/TileWMS';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import olPointerInteraction from 'ol/interaction/Pointer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';

import { DrawObjectModalComponent } from '@pgis/shared/components/draw-object-modal/draw-object-modal.component';
import { MapMeassureService } from './map.meassure.service';
import { GeometriesService } from '../geometries.service';
import { GeometryFilesService } from '../geometry-files.service';
import { ObjectDragService } from './object-drag.service';

import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { VectorSourceProviderService } from './vector-source-provider.service';
import { User, ObjectFilter, Classifier, MapActions, DrawObject } from '@pgis/shared/models';
import { ClassifiersService } from '../classifiers.service';
import { MapGeolocationService } from './map.geolocation.service';
import { MapPopupService } from './map-popup.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  map: Map;
  mapBoxLayer: TileLayer;
  view: View;

  drawInteraction = null;
  baseLayers = [];

  overlayLayers = [];
  serversideLayers = {};
  layers = [];

  overlay: Overlay;

  selectedElementDefaultStyle: Style;
  selectedElement: any;
  isNewElementSelected: boolean = false;
  isUserInteraction: boolean;
  mapViews: any[] = [];

  imageArray: any[] = [];

  public geometryDrawn = new Subject();
  currentAction: MapActions;

  private zoomEventFired: boolean;
  private zoomingOut: boolean;

  private _selectedObject = new Subject();
  public selectedObject = this._selectedObject.asObservable();
  private previousResolution: number;
  url: string;
  activeLayer: Classifier;
  searchCadastreLayer: VectorLayer;

  constructor(private http: HttpClient,
    private modalService: NgbModal,
    private mapMeassureService: MapMeassureService,
    private translateService: TranslateService,
    private geometriesService: GeometriesService,
    private toastr: LocalizedToastrService,
    private vectorSourceProviderService: VectorSourceProviderService,
    private geometryFilesService: GeometryFilesService,
    private objectDragService: ObjectDragService,
    private classifiersService: ClassifiersService,
    private geolocationService: MapGeolocationService,
    private mapPopupService: MapPopupService) {
    this.translateService.onLangChange.subscribe(data => {
      if (this.overlay) this.overlay.setPosition(undefined);
    });
  }
  /**
   * Returns tuple - first base layers loaded promise, second vector layers loaded promise
   */
  private defineLayers(): Promise<any[]> {
    this.layers = [];
    this.vectorSourceProviderService.initAll(this.selectedObject);
    this.layers.push(...this.vectorSourceProviderService.getAllLayers());
    return this.loadLayers();
  }

  initMap(extent?: number[], zoomOnObject: boolean = false): Promise<void> {
    return this.defineLayers().then(layers => {

      const container = document.getElementById('popup');
      const content = document.getElementById('popup-content');
      const mapContainer = document.getElementById('map');

      proj4.defs('EPSG:3059',
      '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      register(proj4);

      this.overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        }
      });

      this.baseLayers = _.filter(layers, { 'overlay': false });
      this.overlayLayers = _.filter(layers, { 'overlay': true });

      let currentMapView = {};
      const lastMapView = JSON.parse(localStorage.getItem('lastMapView'));
      if (lastMapView) {
        currentMapView = lastMapView;
      } else if (window.location.hostname === 'sigulda.tergis.lv'){
        currentMapView = {"center":[2763811.542511325, 7794386.574852075],"zoom":9,"maxZoom":28};
      }
      else if (window.location.hostname === 'kipsala.tergis.lv') {
        currentMapView = {'center':[2680797.19983795,7750415.081571375],'zoom':14,'maxZoom':20};
      }
      else if (window.location.hostname === 'balvi.tergis.lv') {
        currentMapView = {'center':[3046080.876683625, 7775804.549902455],'zoom':9,'maxZoom':20};
      }
      else { currentMapView = {center: Proj.fromLonLat([23.9890828, 56.9713962]), zoom: 10}; }
      this.mapViews.push(currentMapView);   //Setting default map view
      this.view = new View(currentMapView);

      const mergeLayers = _.concat(_.concat(this.baseLayers.map(f => f.olLayer), this.overlayLayers.map(f => f.olLayer)), this.layers);
      this.map = new Map({
        target: 'map',
        layers: mergeLayers,
        view: this.view,
        controls: Control.defaults(),
        overlays: [this.overlay],
      });

      const scaleLineControl = new CanvasScaleLine();
      this.map.addControl(scaleLineControl);
      if (extent) {
        this.zoomToExtent(extent, zoomOnObject);
      }

      this.geolocationService.setMap(this.map);
      const mouseStillMoving = [];
      let mouseOnMap = false;
      this.map.once('postrender',
        () => {
          mapContainer.onmouseleave =
            () => {
              mouseOnMap = false;
            };
          this.map.on('pointermove',
            (evt) => {
              if (evt.dragging) {
                return;
              }

              mouseStillMoving.push(true);
              mouseOnMap = true;
              setTimeout(async () => {
                mouseStillMoving.shift();
                if (mouseStillMoving[0] || !mouseOnMap) {
                  return;
                }
                const feature = this.map.forEachFeatureAtPixel(evt.pixel, (featureAtPixel, layer) => {
                    if (!layer) {
                      return null;
                    }
                    return featureAtPixel;
                  });
                if (!feature) {
                  this.overlay.setPosition(undefined);
                  return;
                }

                const props = feature.getProperties();
                if (!props.id) {
                  return;
                }

                if (!(props.id || props.valdosaSugaNosaukums)) {
                  return;
                }

                await this.mapPopupService.getPopupContent(props, content);
                this.overlay.setPosition(evt.coordinate);
              },
                1000);
            });

          this.reload();
        });

      this.map.on('click',
        () => {
          this.overlay.setPosition(undefined);
          this.isUserInteraction = true;
        });

      this.map.on('pointerdrag',
        () => {
          this.isUserInteraction = true;
        });

      this.map.getView().on('change:resolution',
        e => {
          this.zoomEventFired = true;
          this.zoomingOut = this.previousResolution < e.oldValue;
          this.isUserInteraction = true;
          this.previousResolution = e.oldValue;
        });

      this.map.on('moveend',
        (e) => {
          if (!this.isUserInteraction) {
            return;
          }
          if (this.zoomEventFired) {
            if (!this.zoomingOut) {
              this.reloadLayerData();
            }
            this.overlay.setPosition(undefined);
            this.zoomEventFired = false;
          }
          this.isUserInteraction = false;
          this.rememberView();
        });
      return;
    });

  }

  private zoomToExtent(extent: number[], zoomOnObject: boolean = false): void {
    const view = this.map.getView();
    const mapSize = this.map.getSize();
    if (!view || !mapSize) {
      return;
    }
    if (!zoomOnObject) {
      view.fit(extent, mapSize, { maxZoom: 28 });
      this.rememberView();
    }
    else {
      view.fit(extent, { size: mapSize, maxZoom: 20 });
      this.rememberView(zoomOnObject);
    }
  }

  private rememberView(zoomOnObject?: boolean): void {
    const viewZoom = zoomOnObject && this.map.getView().getZoom() > 20 ? 20 : this.map.getView().getZoom();
    const currentView = {
      center: this.map.getView().getCenter(),
      zoom: viewZoom,
      maxZoom: zoomOnObject ? 20 : 28
    };

    localStorage.setItem('lastMapView', JSON.stringify(currentView));
    this.mapViews.push(currentView);
    if (this.mapViews.length > 5) {
      this.mapViews.shift();
    }
  }

  toPreviousView() {
    if (this.mapViews.length < 2) { // array must contain at least current view and previous view
      return;
    }
    this.overlay.setPosition(undefined);
    this.mapViews.pop(); // remove current view from array

    const previeousView = this.mapViews[this.mapViews.length - 1]; //[this.currentMapView];
    if (!previeousView) {
      return;
    }
    this.isUserInteraction = false;
    const view = new View(previeousView);
    view.on('change:resolution',
      e => {
        this.isUserInteraction = true;
      });
    this.map.setView(view);
  }

  private loadLayers(): Promise<any[]> {
    return this.http.get<any>('api/v1/layers').toPromise().then(layers => {
      const newLayers = [];
      const mapLayers = JSON.parse(localStorage.getItem('selectedBaseLayers'));

      let shownLayers = [];
      const currentUserInfo: User = JSON.parse(localStorage.getItem('currentUser'));
      // if (!currentUserInfo) {       //temporary fix to hide overlay layers from unaouthorized users
      //   layers.forEach(l => {
      //     if (!l.overlay) {
      //       shownLayers.push(l);
      //     }
      //   });
      // }
      // else {
      //   shownLayers = layers;
      // }
      shownLayers = layers;

      shownLayers.forEach(data => {
        if (data.layerType === 'TileLayer') {
          switch (data.sourceType) {
            case 'XYZ':
              const xSource = new XYZ(JSON.parse(data.sourceJson));
              xSource.crossOrigin = 'Anonymous';
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: xSource,
                  visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) ? true : false
                })
              });
              break;
            case 'BingMaps':
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: new BingMaps(JSON.parse(data.sourceJson)),
                  visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) ? true : false
                })
              });
              break;
            case 'TileWMS':
              const layerConfig = JSON.parse(data.sourceJson);
              newLayers.push({
                name: data.name,
                group: data.group,
                overlay: data.overlay,
                olLayer: new TileLayer({
                  source: new TileWMS({ ...layerConfig, crossOrigin: 'anonymous' }),
                  visible: ((!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === data.name).length !== 0) || 
                  (_.isEmpty(mapLayers) && (data.active && data.name === 'Ortofoto') )) ? true : false    //Default Ortofoto map(for sigulda/kipsala/balvi)
                })
              });
              break;
          }
        }
        else {
          return;
        }
      });

      const switchedOnLayer = newLayers.find(l => l.olLayer.getVisible());
      if (!switchedOnLayer && newLayers.length > 0) {
        newLayers[0].olLayer.setVisible(true);
        const layerClone = _.clone(newLayers[0]);
        delete layerClone.olLayer;
        localStorage.setItem('selectedBaseLayers', JSON.stringify([layerClone]));
      }

      return newLayers;
    });
  }

  setFilters(visibleClassifiers: number[], objectFilter?: ObjectFilter) {
    this.vectorSourceProviderService.setVisibleClassifiers(visibleClassifiers);
    this.vectorSourceProviderService.setObjectFilter(objectFilter);
    this.vectorSourceProviderService.clearAll();
  }

  toggleLayer(classifier: Classifier): any {
    if (classifier.checked) {
      this.activeLayer = classifier;
    }
    const extent = this.map.getView().calculateExtent(this.map.getSize());
    const resolution = this.map.getView().getResolution();
    this.overlay.setPosition(undefined);
    if (!classifier.serverside) {
      this.vectorSourceProviderService.toggleLayer(classifier, extent, resolution);
    } else {
      this.vectorSourceProviderService.clearOldGeometries(classifier);
      const allLayers = this.map.getLayers();
      if (classifier.checked) {
        let c = 0;

        allLayers.forEach(f => {
          if (f.type !== 'VECTOR') {
            c++;
          }
        });

        const wmsLayer = new ImageLayer({
          source: new ImageWMS({
            'url': '/api/v1/proxy/wmsLayers/' + classifier.id,
            'params': { 'LAYERS': classifier.id + '_lines,' + classifier.id + '_polygons,' + classifier.id + '_points' },
            crossOrigin: 'anonymous'
          })
        });
        this.serversideLayers[classifier.id] = wmsLayer;
        allLayers.insertAt(c, wmsLayer);
      } else {
        allLayers.remove(this.serversideLayers[classifier.id]);
        delete this.serversideLayers[classifier.id];
      }

    }

  }

  setDrawLine() {
    this.currentAction = MapActions.DrawLine;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }

    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('LineString');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data, this.currentAction));

  }

  setDrawPoint() {
    this.currentAction = MapActions.DrawPoint;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('Point');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data, this.currentAction));
  }

  setSelectElement() {
    this.currentAction = MapActions.SelectObject;
    if(this.overlay) this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = new olSelectInteraction({
      style: new Style({
        fill: new Fill({
          color: '#FF0000'
        }),
        stroke: new Stroke({
          color: '#000000',
          width: 5
        }),
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({ color: '#666666' }),
          stroke: new Stroke({ color: '#bada55', width: 1 })
        })

      }),
      hitTolerance: 4
    });
    const handleEvent = this.drawInteraction.handleEvent;

    this.drawInteraction.handleEvent = (event) => {
      const eventType = event.type;


      this.isNewElementSelected = false;
      const handleBind = handleEvent.bind(this.drawInteraction);
      const result = handleBind(event);

      if (eventType === 'singleclick' && !this.isNewElementSelected) {
        const c = event.coordinate;
        const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
        this.geometriesService.getNearestGeometryId(c[0], c[1], this.currentZoom, selectedLayers).then(feature => {

          if (!feature.noDataFound) {
            this._selectedObject.next({ id: feature.data.id });
            const format = new WKT();
            const geom = format.readGeometryFromText(feature.data.geom);
            const oFeature = new olFeature({
              geometry: geom,
              name: 'selectd geom'
            });
            oFeature.setId(1);
            this.drawInteraction.getOverlay().getSource().clear();
            this.drawInteraction.getOverlay().getSource().addFeature(oFeature);
          }
          else {
            this._selectedObject.next(null);
          }
        });
      }
      return result;
    }
    if(this.map) {
      this.map.addInteraction(this.drawInteraction);
    }
    this.drawInteraction.on('select', (data) => this.onSelectEnd(data));
  }

  setDragElement() {
    this.currentAction = MapActions.DragObject;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = new olPointerInteraction(
      this.objectDragService.dragOptions()
    );
    this.objectDragService.objectDragMouseUp.subscribe(() => {
      this.overlay.setPosition(undefined);
    });
    this.map.addInteraction(this.drawInteraction);
  }

  setDrawPolygon() {
    this.currentAction = MapActions.DrawPolygon;
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.vectorSourceProviderService.getDrawIntersection('Polygon');

    this.map.addInteraction(this.drawInteraction);
    this.drawInteraction.on('drawend', (data) => this.onDrawEnd(data, this.currentAction));
  }

  setMessureTool(type: string) {
    switch (type) {
      case 'line':
        this.currentAction = MapActions.MeassureDistance;
        break;
      case 'area':
        this.currentAction = MapActions.MeassureArea;
        break;
    }
    this.overlay.setPosition(undefined);
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction);
    }
    this.mapMeassureService.disable();
    this.drawInteraction = this.mapMeassureService.enable(this.map, type);
  }

  deselectElement() {
    if (this.selectedElement) {
      this.selectedElement.setStyle(this.selectedElementDefaultStyle);
      this._selectedObject.next(null);
    }
  }

  private onSelectEnd(data) {
    this.isNewElementSelected = true;
    if (data.deselected[0]) {
      const deselectedFeatureId = data.deselected[0].getId();
      const deselectedFeature = this.vectorSourceProviderService.findFeature(deselectedFeatureId);
      if (deselectedFeature) {
        deselectedFeature.setStyle(this.selectedElementDefaultStyle);
      }
    }
    if (data.selected[0]) {
      this.changeSelectedStyle(data.selected[0]);
    }
  }

  private changeSelectedStyle(selected: Feature) {
    if (!selected.getProperties().id) {
      return;
    }

    this.selectedElementDefaultStyle = _.clone(selected.getStyle());

    const geom = selected.getGeometry();
    let elementaGeometrija = '';
    if (geom instanceof olPolygon) {
      elementaGeometrija = this.mapMeassureService.formatArea(geom);
    }
    else if (geom instanceof olLineString) {
      elementaGeometrija = this.mapMeassureService.formatLength(geom);
    }

    selected.setStyle(new Style({
      fill: new Fill({
        color: '#ffffff40'
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 5
      }),
      image: new CircleStyle({
        radius: 15,
        fill: new Fill({ color: '#666666' }),
        stroke: new Stroke({ color: '#bada55', width: 5 })
      })
    }));

    this.selectedElement = selected;
    const selectedObj = selected.getProperties();
    selectedObj.elementaGeometrija = elementaGeometrija;
    delete selectedObj.geometry;
    delete selectedObj.labelPoint;
    if (selectedObj.data) {
      selectedObj.data = JSON.parse(selectedObj.data);
    }
    this._selectedObject.next(selectedObj);
  }

  private onDrawEnd(data, previousAction?: MapActions) {
    this.currentAction = MapActions.None;
    const wkt = new WKT();

    const modal = this.modalService.open(DrawObjectModalComponent);
    modal.componentInstance.activeLayer = this.activeLayer;
    modal.result.then((result: { drawObject: DrawObject, geometryFiles: FormData }) => {
      const childClassifier = result.drawObject.classId || null;
      this.geometriesService
        .addGeometry(wkt.writeFeature(data.feature), result.drawObject.name, result.drawObject.description, result.drawObject.classId, result.drawObject.isPublic, result.drawObject.data, result.drawObject.userEmail)
        .then(async (dt) => {
          if (result.geometryFiles) {
            result.geometryFiles.append('geometryId', dt.toString());
            await this.geometryFilesService.addFiles(result.geometryFiles);
          }
          this.geometryDrawn.next(true);
          this.currentAction = previousAction || MapActions.SelectObject;
          this.toastr.success('MAP.OBJECT_ADDED');
          if (childClassifier) {    //Added object layer is now active layer
            this.classifiersService.getClassifier(result.drawObject.classId).then(c => {
              this.activeLayer = c;
            });
          }
        })
        .catch((err) => {
          data.target.source_.removeFeature(data.feature);
          this.toastr.error('MAP.ERROR_OBJECT_ADD');
        });
    },
      (reason) => {
        data.target.source_.removeFeature(data.feature);
      });
  }

  updateFeature(feature: DrawObject) {
    return this.geometriesService.updateGeometry(feature);
  }

  deleteFeature(id) {
    this.geometriesService.deleteGeometry(id).then(rez => {
      if (this.vectorSourceProviderService.removeFeature(id)) {
        this.setSelectElement();
        this.reload();
      }
    });
  }

  reload() {
    if (!this.map) {
      return;
    }
    this.map.updateSize();
    this.map.renderSync();
  }

  zoomToLayer(classifier: Classifier, objectFilter?: ObjectFilter) {
    this.geometriesService.getExtent(classifier.id, objectFilter).then(extentCoordinates => {
      this.zoomToExtent(extentCoordinates, true);
    });
  }

  reloadLayerData() {
    this.vectorSourceProviderService.clearAll();
  }

  getVisibleFeatures(): Feature[] {
    const visibleFeatures: Feature[] = [];
    const extent = this.map.getView().calculateExtent(this.map.getSize());
    this.vectorSourceProviderService.getAllLayerSources().forEach((source: VectorSource) => {
      source.forEachFeatureInExtent(extent, (feature: Feature) => {
        visibleFeatures.push(feature);
      });
    });

    return visibleFeatures;
  }

  get currentZoom(): number | null {
    if (!this.map) {
      return null;
    }
    return this.map.getView().getZoom().toFixed();
  }

  createPolyCadastre(polygonData) {
    if (polygonData.geometry['rings']) {
      //Coordinate transform
      for (let j = 0; j < polygonData.geometry['rings'].length; j++) {
        for (let index = 0; index < polygonData.geometry['rings'][j].length; index++) {
          const coordinates = polygonData.geometry['rings'][j][index];
          const transformedCoordinates = Proj.transform(coordinates, 'EPSG:3059', 'EPSG:3857');
          polygonData.geometry['rings'][j][index] = transformedCoordinates;
        }
      }
    }
    const feature = new olFeature({
      geometry: new olPolygon(polygonData.geometry['rings'])
    });

    const vectorSource = new VectorSource({
      features: [feature]
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource
    });

    if (this.searchCadastreLayer) {
      this.map.removeLayer(this.searchCadastreLayer);   //Remove previous search cadastre layer
    }
    this.searchCadastreLayer = vectorLayer;   //Set current search cadastre layer

    const layerExtent = vectorLayer.getSource().getExtent();
    this.map.addLayer(vectorLayer);
    this.map.getView().fit(layerExtent, this.map.getSize());
  }
}
