import { Component } from '@angular/core';
declare var require: any;
const { version: appVersion } = require('../../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
  appVersion: string;

  constructor() {
    this.appVersion = appVersion;
  }
}
