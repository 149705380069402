import { Component } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '@pgis/core/services/authentication.service';
import { LocalizedRouterService } from '@pgis/core/services/localized-router.service';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
}) 
export class AppHeaderComponent {

  languages: any = [
    { code: 'en', label: 'English', lang_code: 'EN', icon: 'flag-icon-us' },
    { code: 'lv', label: 'Latviešu', lang_code: 'LV', icon: 'flag-icon-lv' },
    { code: 'ru', label: 'Русский', lang_code: 'RU', icon: 'flag-icon-ru' }
  ];
  currentLang: string;
  defaultRoute: string;

  constructor(private localizeRouterService: LocalizeRouterService,
    private localizedRouter: LocalizedRouterService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal) {
    this.currentLang = this.localizeRouterService.parser.currentLang || this.localizeRouterService.parser.defaultLang;
    this.defaultRoute = environment.defaultRoute;
  }

  switchLanguage(langCode: string): void {
    this.localizeRouterService.changeLanguage(langCode, null, false);
    this.currentLang = this.localizeRouterService.parser.currentLang = langCode;
  }

  mapRoute() {
    return this.localizedRouter.isMapRoute() || this.router.url.toLowerCase().indexOf('/dashboard') >= 0; //map view or object task on map
  }

  getCurrentUser() {
    return this.authenticationService.getCurrentUser();
  }

  checkMobileWidth() {
    if (window.innerWidth <= 400) {
      return true;
    }
    return false;
  }

  openHelp(): void {
    this.modalService.open(HelpDialogComponent, { size: 'lg' }).result.then(() => {
    }, () => {
    });
  }

  goToDefault() {
    const routerUrl = this.router.url.split('/');
    routerUrl.splice(1, 1);   //Removing language prefix
    const currentRoute = routerUrl.join('/');
    this.localizedRouter.navigate(['/' + this.defaultRoute], { queryParams: { previousRoute: currentRoute } });
  }
}
