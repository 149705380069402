import { Injectable, Inject } from '@angular/core';
import olDraw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { LayerSource } from './layer-source';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { Classifier } from '@pgis/shared/models/classifiers.model';
import { Observable } from 'rxjs';
import olFeature from 'ol/Feature';

@Injectable({
  providedIn: 'root'
})
export class VectorSourceProviderService {

  constructor(@Inject(LayerSource) private layerSources: LayerSource[]) {
  }

  initAll(selectedObject: Observable<any>): void {
    this.layerSources.forEach(source => {
      source.initSource(selectedObject);
    });
  }

  getAllLayers(): VectorLayer[] {
    return this.layerSources.map(l => l.getLayer());
  }

  getAllLayerSources(): VectorSource[] {
    return this.layerSources.map(l => l.source);
  }

  setVisibleClassifiers(visibleClassifiers: number[]): void {
    this.layerSources.forEach(source => {
      source.visibleClassifiers = visibleClassifiers;
    });
  }
  getVisibleClassifiers(): number[] {
    return this.layerSources[0].visibleClassifiers;
  }

  setObjectFilter(objectFilter: ObjectFilter): void {
    this.layerSources.forEach(source => {
      source.objectFilter = objectFilter;
    });
  }

  clearAll(): void {
    this.layerSources.forEach(source => {
      source.clear();
    });
  }

  removeFeature(id: number): boolean {
    let featureRemoved = false;
    this.layerSources.forEach(source => {
      if (source.removeFeature(id)) {
        featureRemoved = true;
        return;
      }
    });
    return featureRemoved;
  }

  findFeature(id: number): olFeature {
    for (let index = 0; index < this.layerSources.length; index++) {
      const feature = this.layerSources[index].findFeature(id);
      if (feature) {
        return feature;
      }
    }
    return null;
  }

  getDrawIntersection(type: string): olDraw {
    const olDrawProvider = this.layerSources.find(l => l.type === type);
    if (!olDrawProvider) {
      return null;
    }
    return olDrawProvider.getDrawIntersection();
  }

  toggleLayer(classifier: Classifier, extent: number[], resolution: number): void {
    if (classifier.checked) {
      this.layerSources.forEach(source => {
         source.loadSingleLayer(classifier.id, extent, resolution);
      });
      return;
    }
    this.clearOldGeometries(classifier);  
  }
  clearOldGeometries(classifier: Classifier): void {
    this.layerSources.forEach(source => {
      source.removeFeatures(classifier.id);
    });

    const firstSource = this.layerSources[0];
    if (this.layerSources[0] && firstSource.visibleClassifiers) {
      const index = firstSource.visibleClassifiers.indexOf(classifier.id);
      if (index === -1) {
        return null;
      }
      firstSource.visibleClassifiers.splice(index, 1);
    }
  }
}
