import { Injectable } from '@angular/core';
import {ConfirmDialogComponent} from '@pgis/shared/components/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ConfirmDialogService {

  constructor(private modalService: NgbModal) {}

  showConfirmationDialog(msg: string): Promise<boolean> {
    const confirmDialog = this.modalService.open(ConfirmDialogComponent);
    confirmDialog.componentInstance.confirmationMsg = msg;
    return confirmDialog.result.then((confirmed) => {
        return Promise.resolve(confirmed);
      },
      (rejected) => {
        return Promise.resolve(false);
      });
  }
}
