import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LocalizedRouterService } from '@pgis/core/services/localized-router.service';
import { AuthenticationService } from '@pgis/core/services/authentication.service';
import { ImageEnlargeService } from '@pgis/core/services/image-enlarge.service';

@Component({
  selector: 'pgis-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit {

  isAuthenticated: boolean;
  fullImage: string;

  constructor(private localizedRouter: LocalizedRouterService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private imageEnlargeService: ImageEnlargeService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = !!this.authenticationService.getCurrentUser();
    this.imageEnlargeService.enlargeImage.subscribe(img => {
      this.fullImage = img;
    });
  }

  isMapRoute() {
    return this.localizedRouter.isMapRoute() || this.router.url.toLowerCase().indexOf('/dashboard') >= 0;  //map view or object task on map
  }

  clearEnlargedImage(): void {
    this.imageEnlargeService.close();
  }
}
