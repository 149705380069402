import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LocalizedRouterService {

  constructor(private localizeRouterService: LocalizeRouterService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    if (commands.length !== 0) {
      commands[0] = this.localizeRouterService.translateRoute(commands[0]);
    }
    return this.router.navigate(commands, extras);
  }

  translateRoute(path: string): string {
    return (this.localizeRouterService.translateRoute(path)) as any;
  }

  isMapRoute() {
    const activatedRoutes = this.flatten(this.activatedRoute.children);
    activatedRoutes.unshift(this.activatedRoute);

    return _.some(activatedRoutes, route => route.snapshot.data.isMapRoute);
  }

  private flatten(activatedRoutes: ActivatedRoute[]) {
    return activatedRoutes.reduce((acc, route) => {
        acc = acc.concat(route);
        if (route.children) {
          acc = acc.concat(this.flatten(route.children));
        }
        return acc;
      },
      []);
  }
}
