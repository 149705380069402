import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { User } from '@pgis/shared';

@Component({
  selector: 'pgis-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent implements OnInit {

  @Input()
  selectedIcon: string;

  @Input()
  classId: string;

  @Output()
  onCustomIconDelete: EventEmitter<string> = new EventEmitter();

  @Output()
  selectedIconChange: EventEmitter<string> = new EventEmitter();

  currentUser: User;
  customIcons: { url: string, base64Img: string | ArrayBuffer }[] = [];
  cachedIcons: { url: string, base64Img: string | ArrayBuffer }[] = [];
  deleteLabel: string;
  initialIcon: string;

  constructor(private toastr: LocalizedToastrService,
    private profileFilesService: ProfileFilesService,
    private translateService: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.deleteLabel = this.translateService.instant('CLASSIFIERS.DELETE_ICON');
    this.initialIcon = this.selectedIcon;
    if (localStorage['customIcons_' + this.currentUser.companyId]) {
      this.cachedIcons = JSON.parse(localStorage['customIcons_' + this.currentUser.companyId]);
    }
    await this.loadCustomImages();
  }

  async uploadCustomIcons(event: any): Promise<void> {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const formData = new FormData();
    for (const file of event.target.files) {
      formData.append('customIcon', file);
    }
    try {
      await this.profileFilesService.uploadCustomIcons(formData);
      await this.loadCustomImages();
      this.toastr.success('PROFILE.IMAGE_UPLOADED');
    } catch {
      this.toastr.error('PROFILE.ERROR_LOADING_LOGO');
    }
  }

  selectIcon(icon): void {
    if (this.selectedIcon === icon.url) {
      this.selectedIcon = '';
      this.selectedIconChange.emit('');
      return;
    }
    this.selectedIcon = icon.url;
    this.selectedIconChange.emit(icon.url);
  }

  async deleteIcon(): Promise<void> {
    if (!this.selectedIcon) {
      return;
    }
    else {
      const deletedIcon = this.selectedIcon;
      await this.profileFilesService.deleteCustomIcon(this.selectedIcon);

      //Deleting icon from localStorage
      const deletedIcons = _.remove(this.cachedIcons, c => c.url.split(/_(.+)/)[1] == deletedIcon);     //Delete this icon from all other company layer styles
      localStorage['customIcons_' + this.currentUser.companyId] = JSON.stringify(this.cachedIcons);

      //Deleting icon from modal & geom style
      const deleteIcon = _.remove(this.customIcons, c => c.url == deletedIcon);
      this.selectedIcon = this.customIcons.some(c => c.url == this.initialIcon) ? this.initialIcon : '';
      this.onCustomIconDelete.emit(deletedIcon);
      this.toastr.success('CLASSIFIERS.ICON_DELETED');
    }
  }

  private async loadCustomImages(): Promise<void> {
    this.customIcons = [];
    const customIconUrls = await this.profileFilesService.getAllCustomIconUrls();

    customIconUrls.forEach(async url => {
      const cachedIcon = this.classId ? this.cachedIcons.find(c => c.url == this.classId + '_' + url) : null;
      if (!cachedIcon) {
        try {
          const data = await this.profileFilesService.getCustomIcon(this.currentUser.companyId, url).toPromise();
          this.createImageFromBlob(data, url);
        }
        catch(err) {
          console.log(err);
        }
      }
      else {
        this.customIcons.push({
          url: url,
          base64Img: cachedIcon.base64Img
        });
        this.customIcons = _.sortBy(this.customIcons, 'url');
      }
    });
  }

  private createImageFromBlob(image: Blob, url: string) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.customIcons.push({
        url: url,
        base64Img: reader.result
      });
      this.customIcons = _.sortBy(this.customIcons, 'url');     //Push icon in custom icon list

      const cachedIcon = this.classId + '_' + url;
      if (!this.cachedIcons.some(c => c.url == cachedIcon)) {   //Push loaded icon in local storage
        this.cachedIcons.push({
          url: cachedIcon,
          base64Img: reader.result
        });
        localStorage['customIcons_' + this.currentUser.companyId] = JSON.stringify(this.cachedIcons);
      }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
