import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { LayerField } from '@pgis/shared/models/layer-field.model';
import * as _ from 'lodash';

@Component({
  selector: 'pgis-layer-fields',
  templateUrl: './layer-fields.component.html',
  styleUrls: ['./layer-fields.component.scss']
})
export class LayerFieldsComponent implements OnInit {

  @Input()
  layerFields: LayerField[];

  @Input()
  editable: boolean;

  @Output()
  onLayerFieldsChange: EventEmitter<LayerField[]> = new EventEmitter<LayerField[]>();

  layerFieldsForm: FormGroup;
  initialLayerFields: LayerField[] = [];
  fieldTypes: any[] = ['text', 'select'];
  layerFieldsArr: any[] = [{name: '', type: 'text'}];

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.layerFieldsForm = this.formBuilder.group(
      {
        fields: this.formBuilder.array([])
      }
    );
    if (this.layerFields) {
      this.layerFields.forEach(lf => {
        lf.oldValue = '';
        const layerFielInputs = this.createItem(lf.name, lf.type, lf.oldValue || '', lf.optionsArray);
        if (!this.editable) {
          layerFielInputs.disable();
        }
        this.fields().push(layerFielInputs);
      });
      this.initialLayerFields = _.clone(this.layerFields);
    }

    this.layerFieldsForm.valueChanges.subscribe(val => {
      this.updateLayerFields(val);
    });
  }

  fields(): FormArray {
    return this.layerFieldsForm.get('fields') as FormArray;
  }

  fieldOptionsArray(fieldIndex: number): FormArray {
    return this.fields().at(fieldIndex).get('optionsArray') as FormArray;
  }

  addField() {
    this.fields().push(this.createItem('', 'text', null));
    this.updateLayerFields(this.layerFieldsForm.value);
  }

  addNameField(nameIndex: number) {
    this.fieldOptionsArray(nameIndex).push(this.createNameField(''));
    this.updateLayerFields(this.layerFieldsForm.value);
  }

  removeNameField(nameIndex: number, fieldIndex: number) {
    this.fieldOptionsArray(nameIndex).removeAt(fieldIndex);
    this.updateLayerFields(this.layerFieldsForm.value);
  }

  createItem(name: string, type: string, oldValue: string, optionsArray = []): FormGroup {
    const arr = new FormArray([]);

    optionsArray.forEach(option => {
      arr.push(this.formBuilder.group({
        fieldName: option.fieldName,
        oldName: ''
      }));
    });
    return this.formBuilder.group({
      name: new FormControl(name, Validators.required),
      optionsArray: arr,
      type: new FormControl(type, Validators.required),
      oldValue: new FormControl(oldValue)
    });
  }

  createNameField(oldName?: string): FormGroup {
    return this.formBuilder.group({
      fieldName: '',
      oldName: ''
    });
  }

  removeField(index: number) {
    (<FormArray>this.layerFieldsForm.controls.fields).removeAt(index);
    this.updateLayerFields(this.layerFieldsForm.value);
  }

  updateLayerFields(formValue: any) {
    this.layerFields.splice(0, this.layerFields.length);

    for (let i = 0; i < formValue.fields.length; i++) {
      let oldValParameter;
      if (this.initialLayerFields.length > i) {
        if (formValue.fields[i].name === this.initialLayerFields[i].name) {
          oldValParameter = '';
        }
        else {
          oldValParameter = this.initialLayerFields[i].name;
        }
      }
      else {
        oldValParameter = 'new_field';
      }

      this.layerFields.push({
        name: formValue.fields[i].name,
        optionsArray: formValue.fields[i].optionsArray,
        type: formValue.fields[i].type,
        oldValue: oldValParameter,
        fieldIndex: 0
      });
    }
    this.onLayerFieldsChange.next();
  }

  changeType(ev, index?) {
    if(ev.currentTarget.value && ev.currentTarget.value === 'select') {
     if (this.fields().value[index].optionsArray.length === 0) {
      this.addNameField(index);
     }
    }
  }
}
