import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { ClassifiersService, GeometryFilesService, LocalizedToastrService, MapService, GeometriesService, AuthenticationService, UpvotesService } from '@pgis/core/services';
import { GeometryFiles, Classifier, Feature, Upvotes } from '@pgis/shared/models';
import { ConfirmDialogService } from '@pgis/services/confirm-dialog.service';
import { DrawObjectModalComponent } from '@pgis/shared/components/draw-object-modal/draw-object-modal.component';

@Component({
  selector: 'pgis-info-tab',
  templateUrl: './info-tab.component.html',
  styleUrls: ['./info-tab.component.scss']
})
export class InfoTabComponent implements OnInit {

  _selectedObject: Feature;
  @Input()
  set selectedObject(selectedObject: Feature) {
    this._selectedObject = selectedObject;
    this.loadData();
  }
  get selectedObject() {
    return this._selectedObject;
  }

  @Output()
  onFeatureDeselect: EventEmitter<number> = new EventEmitter<number>();

  selectedObjectFull: Feature;
  classifierStatuses: Classifier[];
  classifiers: Classifier[];
  geometryFiles: GeometryFiles[];
  upvoteComment: string;
  upvotesList: Upvotes[];
  upvotesCount: number;

  deleteLabel: string;
  editLabel: string;
  upvoteLabel: string;
  upvoteCommentLabel: string;
  upvoteCountLabel: string;

  constructor(private classifiersService: ClassifiersService,
    private geometryFilesService: GeometryFilesService,
    private toastr: LocalizedToastrService,
    private translateService: TranslateService,
    private confirmDialogService: ConfirmDialogService,
    private mapService: MapService,
    private geometriesService: GeometriesService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private upvotesService: UpvotesService) {
    this.translateService.onLangChange.subscribe(() => {
      this.setTranslationTooltips();
    });
  }

  ngOnInit() {
    this.setTranslationTooltips();
  }

  async loadData() {
    this.selectedObjectFull = null;
    this.upvotesList = await this.upvotesService.getGeometryUpvotes(this.selectedObject.id);
    this.upvotesCount = this.upvotesList.length;

    this.classifierStatuses = await this.classifiersService.getChildClassifiers(2);
    this.selectedObjectFull = await this.geometriesService.getGeometryData(this.selectedObject.id);
    this.geometryFiles = await this.geometryFilesService.getGeometryFiles(this.selectedObject.id);

    this.selectedObjectFull.parsedData = this.selectedObjectFull.data ? JSON.parse(this.selectedObjectFull.data) : null;
  }

  editFeature(selectedObject: Feature) {
    const modalRef = this.modalService.open(DrawObjectModalComponent);
    modalRef.componentInstance.drawObject = _.clone(selectedObject);

    modalRef.result.then((result) => {
      this.mapService.updateFeature(result).then(rez => {
        this.selectedObject = _.cloneDeep(result);
        this.mapService.toggleLayer(result);
        this.mapService.reloadLayerData();
      },
        (err) => {
          this.toastr.error(err.error);
        });
    }, (err) => {
    });
  }

  deleteFeature(object: Feature) {
    const deleteMessage = object && object.name ? 
    this.translateService.instant('CONFIRM_DELETE', { object: object.name }) : 
    this.translateService.instant('CONFIRM_DELETE_OBJECT');
    this.confirmDialogService.showConfirmationDialog(deleteMessage).then(
      (confirmed) => {
        if (confirmed) {
          this.mapService.deleteFeature(object.id);
          this.toastr.success('REPORTS.OBJECT_DELETED');
          this.onFeatureDeselect.next();
        }
      });
  }

  exportData(selectedObject: Feature) {
    if (selectedObject == null) {
      this.toastr.error('ERROR');
      return;
    }
    this.geometriesService.downloadCsv({ id: selectedObject.id, name: selectedObject.name }).then(() => {
      this.toastr.success('MAP.FILE_EXPORTED');
    },
      (err) => {
        console.log('Error downloading file!');
        this.toastr.error('MAP.ERROR_FILE_EXPORT');
      });
  }

  isObject(data) {
    return (typeof data === 'object' && data != null) ? true : false;
  }

  async upvote(selectedObject: Feature, withComment: boolean): Promise<void> {
    const comment = withComment ? this.upvoteComment : null;
    await this.upvotesService.upvoteGeometry(selectedObject.id, comment);
        
    this.upvotesList = await this.upvotesService.getGeometryUpvotes(this.selectedObject.id);
    this.upvotesCount = this.upvotesList.length;
    selectedObject.canUpvote = false;
    this.upvoteComment = null;
  }

  get objectBaseDataKeys() {
    const baseKeys: string[] = ['layerGroupName', 'layerName'];

    return Object.keys(this.selectedObjectFull).filter(k => baseKeys.some(bk => bk === k));
  }

  get getCurrentUser() {
    return this.authenticationService.getCurrentUser();
  }

  setTranslationTooltips() {
    this.deleteLabel = this.translateService.instant('MAP.DELETE');
    this.editLabel = this.translateService.instant('MAP.EDIT');
    this.upvoteLabel = this.translateService.instant('MAP.UPVOTE');
    this.upvoteCommentLabel = this.translateService.instant('MAP.UPVOTE_WITH_COMMENT');
    this.upvoteCountLabel = this.translateService.instant('MAP.UPVOTE_COUNT');
  }
}
