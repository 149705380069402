import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

import { ClassifiersService } from '@pgis/core/services/classifiers.service';
import { LocalizedToastrService } from '@pgis/core/services/localized-toastr.service';
import { ValidationService } from '@pgis/core/services/validation.service';
import { Classifier, User, DrawObject, LayerField } from '../../models';

@Component({
    templateUrl: './draw-object-modal.component.html',
    styleUrls: ['./draw-object-modal.component.scss']
})
export class DrawObjectModalComponent implements OnInit {

    drawObject: DrawObject = new DrawObject();
    activeLayer: Classifier;
    classifiers: Classifier[];
    childClassifiers: Classifier[] = [];
    selectedGroup = null;
    groupObjects: number[] = [];

    selectedClassifier: number;
    selectedChildClassifier: number;

    layerFields: LayerField[];
    featureData: {};

    isPublicUser: boolean;
    isEditMode: boolean;
    geometryFiles: FormData;

    constructor(private activeModal: NgbActiveModal,
        private classifiersService: ClassifiersService,
        private validationService: ValidationService,
        private toastr: LocalizedToastrService) {
    }

    ngOnInit() {
        this.featureData = this.drawObject.parsedData;
        if(this.drawObject.parsedData) {
            this.featureData = this.drawObject.parsedData;
        }
        else {
            this.featureData = this.drawObject.data ? JSON.parse(this.drawObject.data) : null;
        }
        this.drawObject.oldClassId = this.drawObject.classId;
        this.selectedChildClassifier = this.drawObject.classId || 0;
        const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
        this.isPublicUser = !currentUser || !currentUser.token;

        this.classifiersService.getChildClassifiersWithChildren(1).then(data => {
            this.classifiers = data;
            if (this.drawObject.objectId) { //edit mode
                this.isEditMode = true;
            } else if (this.activeLayer) {
                this.drawObject.objectId = this.activeLayer.id1;
                this.drawObject.classId = this.activeLayer.id;
            }

            this.childClassifiers.length = 0;
            data.forEach(c => {
              c.childClassifiers.forEach(childClassifier => {
                this.childClassifiers.push(childClassifier);
              });
            });
            this.selectedClassifier = this.drawObject.objectId || 0;
            this.selectedChildClassifier = this.drawObject.classId || 0;

            this.parseLayerFields(this.selectedChildClassifier.toString());
            if (this.isPublicUser) {
                this.drawObject.isPublic = true;
            }
        });
    }

    getChildClassifiers(objectId: number) {
        if (!objectId) {
            return [];
        }

        const selectedBaseClassifier = this.classifiers.find((c: Classifier) => c.id === Number(objectId));
        if (!selectedBaseClassifier) {
            return [];
        }

        this.childClassifiers = selectedBaseClassifier.childClassifiers;
        this.selectedChildClassifier = 0;
    }

    parseLayerFields(childClassifierId: string) {
        const c = <Classifier>this.childClassifiers.find(cc => cc.id === Number(childClassifierId));
        if (!c) {
            return;
        }
        this.layerFields = c.layerFields;

        if (!this.drawObject.data || !Object.keys(this.drawObject.data).length) {
            this.drawObject.data = {};
            this.featureData = {};
            for (const field of this.layerFields) {
                this.featureData[field.name] = '';
            }
        }
        else{
            this.featureData = this.drawObject.parsedData || (this.drawObject.data ? JSON.parse(this.drawObject.data) : {});
        }
    }

    updateGeometryFiles(geometryFiles: FormData): void {
        this.geometryFiles = geometryFiles;
    }

    onSubmit(form: FormGroup) {
        if (!form.valid) {
            this.validationService.markAllDirty((<any>form).form);
            return;
        }

        if (form.value.objectId === 0 || form.value.classId === 0) {
            this.toastr.error('CLASSIFIERS.CHOOSE_OBJECT');
            return;
        }
        this.drawObject.classId = form.value.classId || this.drawObject.classId;
        const chosenClassifier = this.childClassifiers.find(c => c.id == this.drawObject.classId);

        this.drawObject.objectId = chosenClassifier.id1 || this.drawObject.objectId;

        for (const field of this.layerFields) {
            this.featureData[field.name] = this.featureData[field.name] || form.value[field.name];
        }
        this.drawObject.data = this.featureData;
        this.drawObject.isPublic = true;

        if (this.isEditMode) {
            this.activeModal.close(this.drawObject);
        } else {
            this.activeModal.close({
                drawObject: this.drawObject,
                geometryFiles: this.geometryFiles
            });
        }
    }

    closeModal() {
        this.activeModal.dismiss('Modal closed');
    }
}
