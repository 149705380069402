import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as Proj from 'ol/proj';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { filter } from 'rxjs/operators';

import { MapService } from '@pgis/core/services/map/map.service';
import { LocalizedRouterService } from '@pgis/core/services/localized-router.service';
import { LocalizedToastrService } from '@pgis/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VzdSearchDialogComponent } from '@pgis/shared/components';
import { VzdSearchDialogService } from '@pgis/shared/components/vzd-search-dialog/vzd-search-dialog.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './app-breadcrumbs.component.html',
  styleUrls: ['./app-breadcrumbs.component.scss']
})
export class AppBreadcrumbsComponent implements OnInit {

  breadcrumbs: Array<Object>;

  VZDSearchString: string = '';
  googleSearchString: string = '';
  showVZD: boolean = false;
  searchSelect: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localizeRouterService: LocalizeRouterService,
    private mapService: MapService,
    private localizedRouter: LocalizedRouterService,
    private vzdSearchDialogService: VzdSearchDialogService,
    private toastr: LocalizedToastrService,
    private modalService: NgbModal,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.showCurrentBreadcrumbs();
    });
  }

  ngOnInit() {
    this.showCurrentBreadcrumbs();
    this.searchSelect = 'google';
  }

  switchLanguage(langCode: string): void {
    this.localizeRouterService.changeLanguage(langCode, null, false);
  }

  showCurrentBreadcrumbs() {
    this.breadcrumbs = [];
    let currentRoute = this.route.root,
      url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      // tslint:disable-next-line:no-shadowed-variable
      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          this.breadcrumbs.push({
            label: route.snapshot.data,
            url: url
          });
          currentRoute = route;
        }
      });
    } while (currentRoute);
  }

  onAddressChange($event) {
    const lat = $event.geometry.location.lat();
    const lon = $event.geometry.location.lng();
    this.mapService.map.getView().setCenter(Proj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857'));
    //default zoom for all searches
    this.mapService.map.getView().setZoom(15);
  }

  onInputChange(value) {
    if (value.length === 11 && isNumeric(value)) {
      console.log('Meklē kadastru - ' + value);
    }
  }

  mapRoute() {
    return this.localizedRouter.isMapRoute();
  }

  async keyDownFunction(event) {
    if (event.keyCode === 13 && this.VZDSearchString.length > 4) {
      let searchResult = null;
      if (/^\d+$/.test(this.VZDSearchString)){   //Check if searching cadastre
        const cadastreGroup = this.VZDSearchString.length == 7;    //7 digit cadastre = cadastre group; 11 = cadastre
        searchResult = await this.vzdSearchDialogService.findCadastre(this.VZDSearchString, cadastreGroup).catch((err) => {
          this.toastr.error('ERROR');
        });
        if(searchResult.results && searchResult.results.length > 0) {
          this.mapService.createPolyCadastre(searchResult.results[0]);
        }
        else {
          this.toastr.error('MAP.NO_CADASTRE');
        }
      }
      else {
        searchResult = await this.vzdSearchDialogService.findAddress(this.VZDSearchString).catch((err) => {
          this.toastr.error('ERROR');
        });
        const ref = this.modalService.open(VzdSearchDialogComponent);
        ref.componentInstance.searchString = this.VZDSearchString;
        ref.componentInstance.searchResult = searchResult;
        ref.result.then(data => {
        });
      }
    }
  }

  onSearchTypeChange(value) {
    this.showVZD = value === 'vzd' ? true : false;
  }
}
