import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from '@pgis/core/services/loader.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ToastsManager } from 'ng6-toastr/ng2-toastr';
import { AuthenticationService, LocalizedRouterService } from '@pgis/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import * as moment from 'moment';
import { ConfirmDialogService } from './services/confirm-dialog.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<router-outlet>
                <span *ngIf="showLoader" class="loading"></span>
             </router-outlet>`,
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  tokenMonitorSubscription: Subscription;
  showLoader: boolean;

  constructor(private router: Router,
    private loaderService: LoaderService,
    localizeRouterService: LocalizeRouterService,
    protected localizedRouter: LocalizedRouterService,
    toastr: ToastsManager,
    vRef: ViewContainerRef,
    private authenticationService: AuthenticationService,
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService,
    ngModalConfig: NgbModalConfig,
    private confirmDialogService: ConfirmDialogService) {
    localizeRouterService.parser.defaultLang = 'lv';
    translateService.setDefaultLang('lv');
    translateService.use('lv');
    toastr.setRootViewContainerRef(vRef);
    ngModalConfig.backdrop = 'static';
    ngModalConfig.keyboard = false;
  }

  ngOnInit() {
    if (!this.authenticationService.getCurrentUser()) {
      document.querySelector('body').classList.add('sidebar-hidden');
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.loaderService.status
      .subscribe((val: boolean) => {
        setTimeout(() => { this.showLoader = val; }, 0); // hack to prevent error messages in dev mode
      });

    // Translations for cookie disclaimer
    this.translateCookie();
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.translateCookie();
    });

    this.monitorTokenValidity();
  }

  private translateCookie() {
    this.translateService
      .get(['COOKIE.MESSAGE', 'COOKIE.DISMISS', 'COOKIE.LINK'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.message = data['COOKIE.MESSAGE'];
        this.ccService.getConfig().content.dismiss = data['COOKIE.DISMISS'];
        this.ccService.getConfig().content.link = data['COOKIE.LINK'];
        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig());
      });
  }

  private monitorTokenValidity() {
    this.tokenMonitorSubscription = interval(30000).subscribe(() => {
      const user = this.authenticationService.getCurrentUser();
      if (!user) {
        return;
      }

      if (moment(user.expiresAt) < moment.utc()) {
        this.confirmDialogService.showConfirmationDialog(this.translateService.instant('SESSION_EXPIRED')).then((goToLogin: boolean) => {
          if (goToLogin) {
            this.authenticationService.logout().subscribe(() => {
              this.localizedRouter.navigate(['/auth/login']);
            });
          }
        });
        this.tokenMonitorSubscription.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.tokenMonitorSubscription) {
      this.tokenMonitorSubscription.unsubscribe();
    }
  }
}
