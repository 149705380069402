import { Component, OnInit } from '@angular/core';
import { HelpService } from './help.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pgis-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {

  helpFiles: string[];
  helpPDF: any;
  downloadLabel: string;

  constructor(private helpService: HelpService,
    private activeModal: NgbActiveModal,
    private translateService: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.downloadLabel = this.translateService.instant('DOWNLOAD_HELP');
    // this.helpFiles = await this.helpService.getFiles();
    this.helpService.getFileString().then(res => {
      this.helpPDF = this.convertDataURIToBinary(res);
      this.helpService.getFiles().then(files => {
        this.helpFiles = files;
      });
    });
  }

  async downloadFile(fileName?: string): Promise<void> {
    const downloadedFile = fileName || this.helpFiles.length > 0 ? this.helpFiles[0] : '';
    await this.helpService.downloadFile(downloadedFile);
  }

  convertDataURIToBinary(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
