import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class HelpService {
    constructor(private httpClient: HttpClient) { }

    getFiles(): Promise<string[]> {
        return this.httpClient.get<string[]>('/api/v1/help').toPromise();
    }

    downloadFile(fileName: string): Promise<void> {
        return this.httpClient.get('/api/v1/help/' + fileName, { responseType: 'blob' as 'json' }).toPromise().then(res => {
            saveAs(res, fileName);
        });
    }

    getFileString(): Promise<string> {
        return this.httpClient.get<string>('/api/v1/help/transform').toPromise();
    }
}
