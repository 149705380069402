import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VzdSearchDialogService {

  constructor(public http: HttpClient) {

  }
  findAddress(address: string) {
    return this.http.get<any>('api/v1/vzd/find/' + address).toPromise();
  }
  findCadastre(cadastreNumber: string, cadastreGroup: boolean = false) {
    return this.http.get<any>('api/v1/vzd/find-cadastre/' + cadastreNumber + '/' + cadastreGroup).toPromise();
  }
}
