import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty({ onlySelf: true }); // mark group
      control.markAsTouched({ onlySelf: true });
      const ctrl = <any>control;
      for (const inner in ctrl.controls) {
        if (ctrl.controls.hasOwnProperty(inner)) {
          this.markAllDirty(ctrl.controls[inner] as AbstractControl);
        }
      }
      return;
    }
    (<FormControl>(control)).markAsDirty({ onlySelf: true });
    (<FormControl>(control)).markAsTouched({ onlySelf: true });
  }
}
