export const navigation = [
  {
    name: 'MAP.MAP',
    url: '/dashboard',
    icon: 'icon-map',
  },
  {
    name: 'CLASSIFIERS.CLASSIFIERS',
    url: '/classifiers',
    icon: 'icon-layers',
    modulePermissions: ['Admin', 'Write'],
    children: [
      {
        name: 'CLASSIFIERS.LAYER_GROUPS',
        url: '/classifiers/layer-groups',
        icon: 'fa fa-map',
        permissions: ['Admin', 'Write']
      },
      {
        name: 'CLASSIFIERS.STATUS_GROUPS',
        url: '/classifiers/status-groups',
        icon: 'fa fa-signal',
        permissions: ['Admin', 'Write']
      }
    ]
  },
  {
    name: 'FOREST_REPORTS.REPORTS',
    url: '/reports',
    icon: 'icon-docs',
    modulePermissions: ['Admin', 'Write', 'Read'],
    children: [
      {
        name: 'REPORTS.LAYER_REPORTS',
        url: '/reports/layer-reports',
        icon: 'fa fa-file-text-o',
        permissions: ['Admin', 'Write', 'Read']
      },
      {
        name: 'REPORTS.CUSTOM_REPORTS',
        url: '/reports/custom-reports',
        icon: 'icon-calendar',
        permissions: ['Admin', 'Write', 'Read'],
        modulePermissions: ['hasCustomReports']
      }
    ]
  },
  {
    name: 'ADMIN',
    url: '/admin',
    icon: 'fa fa-user-circle',
    modulePermissions: ['Admin'],
    children: [
      {
        name: 'ADMIN_VIEW.USERS_GROUPS',
        url: '/admin/users-groups',
        icon: 'fa fa-users',
        permissions: ['Admin']
      },
      {
        name: 'ADMIN_VIEW.LOG',
        url: '/admin/log',
        icon: 'fa fa-clipboard',
        permissions: ['Admin']
      }
    ]
  }
];
