import { Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import olFeature from 'ol/Feature';
import WKT from 'ol/format/WKT.js';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import olDraw from 'ol/interaction/Draw';

import { LayerSource } from './layer-source';
import { GeometriesService } from '../geometries.service';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { GeomsWithStyles } from '@pgis/shared/models/geoms-with-styles.model';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';

@Injectable({
  providedIn: 'root'
})
export class LineSourceService extends LayerSource {

  type: string = 'LineString';
  source: VectorSource;
  loaded: {};
  objectFilter: ObjectFilter;

  constructor(private geometriesService: GeometriesService,
    profileFilesService: ProfileFilesService) {
    super(profileFilesService);
  }
  
  loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void> {
    return this.geometriesService.getLines(extent, zoom, classifiersToLoad, this.loaded, this.objectFilter).then((geomFeatures: GeomsWithStyles) => {
      if (!geomFeatures.features) {
        return;
      }
      for (const geomFeature of geomFeatures.features) {
        geomFeature.geomStyle = geomFeatures.geomStyles[geomFeature.geomStyleIndex].geomStyle;
//        const lineCoordinates: any[] = [];
//        for (const g of geomFeature.geom) {
//          lineCoordinates.push([g.x, g.y]);
//        }
        const format = new WKT();
        const geom = format.readGeometryFromText(geomFeature.geom);

        const oFeature = new olFeature({
          geometry: geom,
         // labelPoint: new olPoint(lineCoordinates[0]),
          name: geomFeature.name
        });

        this.addFeatureToSource(oFeature, geomFeature);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getLayer(): VectorLayer {
    return new VectorLayer({
      source: this.source,
      renderBuffer: 1000,
      style: new Style({
        fill: new Fill({
          color: 'red'
        }),
        stroke: new Stroke({
          color: 'blue'
        })
      })
    });
  }

  getDrawIntersection() {
    return new olDraw({
      type: 'LineString',
      source: this.source,
    });
  }
}
