import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintInfo } from '@pgis/shared/models';

@Component({
  templateUrl: './print-modal.component.html'
})

export class PrintModalComponent {

  public printInfo: PrintInfo = new PrintInfo();

  constructor(public activeModal: NgbActiveModal) {
  }

  onSubmit() {
    this.activeModal.close(this.printInfo);
  }
}
