import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'groupLegendBy'
})
export class LegendGroupPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
    this.translateService = translateService;
  }
  transform(collection: any[], property: string): any[] {
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      let splitRule = null;
      if (current[property] && current[property].includes('data.')) {
        splitRule = current[property].substring(current[property].indexOf('.') + 1);       //Removing 'data.'
        splitRule = splitRule.split('=').slice(0, -1).join('=');                           //Removing '=value'
      }
      else if (current[property]) {
        splitRule = current[property];
      }

      if (!splitRule && !previous[this.translateService.instant('MAP.NO_NAME')]) {
        previous[this.translateService.instant('MAP.NO_NAME')] = [current];
      }
      else if (!splitRule && previous[this.translateService.instant('MAP.NO_NAME')]) {
        previous[this.translateService.instant('MAP.NO_NAME')].push(current);
      }
      else if (!previous[splitRule]) {
        previous[splitRule] = [current];
      } else {
        previous[splitRule].push(current);
      }

      return previous;
    }, {});
    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key], id: this.convertToSlug(key) + Math.floor(Math.random() * 100000) }));
  }

  private convertToSlug(text: string): string {
      return text
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
          ;
  }
}
