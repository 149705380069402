import FontSymbol from 'ol-ext/style/FontSymbol';
import FillPattern from 'ol-ext/style/FillPattern';
import StrokePattern from 'ol-ext/style/StrokePattern';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import olFeature from 'ol/Feature';
import olDraw from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle as CircleStyle } from 'ol/style.js';

import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { Feature } from '@pgis/shared/models/feature.model';
import { GeomStyle } from '@pgis/shared';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';
import { Subscription, Observable } from 'rxjs';
import * as _ from 'lodash';
import { OnDestroy } from '@angular/core';


export abstract class LayerSource implements OnDestroy {

  protected customIcons: { url: string, base64Img: string | ArrayBuffer }[] = [];
  protected singleLayerToLoad: number | null;
  visibleClassifiers: number[];
  mapObjectChangeSubscription: Subscription;
  selectedObj: Feature;
  selectedOlFeature: olFeature;
  OLDSelectedOlFeature: olFeature;
  tempLastId: number;
  currentCompanyId: number;

  abstract type: string;
  abstract source: VectorSource;
  abstract loaded = {};
  abstract objectFilter: ObjectFilter;

  abstract getDrawIntersection(): olDraw;
  abstract getLayer(): VectorLayer;
  abstract loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void>;

  constructor(private profileFilesService: ProfileFilesService) {
    this.currentCompanyId = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))['companyId'] : null;
    if (this.currentCompanyId && localStorage['customIcons_' + this.currentCompanyId]) {
      this.customIcons = JSON.parse(localStorage['customIcons_' + this.currentCompanyId]);
    }
    else {
      localStorage.setItem('customIcons_' + this.currentCompanyId, JSON.stringify(this.customIcons));
    }
  }

  initSource(selectedObject: Observable<any>): void {
    this.source = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection) => {
        const zeroZoomResolution = 156543.03390625;
        const zoom = Math.round((Math.log(zeroZoomResolution) * Math.LOG2E - Math.log(resolution) * Math.LOG2E));
        let classifiersToLoad = this.visibleClassifiers;
        if (this.singleLayerToLoad) {
          classifiersToLoad = [this.singleLayerToLoad];
        }

        if (!classifiersToLoad || classifiersToLoad.length === 0) {
          return;
        }
        this.loadGeometries(extent, zoom, classifiersToLoad).then(() => {
          if (this.singleLayerToLoad && !this.visibleClassifiers.includes(this.singleLayerToLoad)) {
            this.visibleClassifiers.push(this.singleLayerToLoad);
          }
          this.singleLayerToLoad = null;
        });
      },
      strategy: (extent: number[], resolution: number): number[][] => {
        return [this.expandExtent(extent)];
      }
    });
    this.mapObjectChangeSubscription = selectedObject.subscribe(newObj => {
      this.selectedObj = newObj;
    });
  }

  clear(): void {
    this.source.clear(false);
    this.loaded = {};
  }

  removeFeature(featureId: number): boolean {
    if (this.source) {
      const feature = this.source.getFeatures().find(f => f.values_.id === featureId);
      if (!feature) {
        return false;
      }

      this.source.removeFeature(feature);
      return true;
    }
    return false;
  }

  findFeature(featureId: number): olFeature {
    if (!this.source) {
      return null;
    }
    return this.source.getFeatures().find(f => f.values_.id === featureId);
  }

  loadSingleLayer(layerId: number, extent: number[], resolution: number) {
    const index = this.visibleClassifiers.indexOf(layerId);
    if (index > -1) {
      return;
    }
    this.singleLayerToLoad = layerId;
    this.source.loader_(this.expandExtent(extent), resolution);
  }

  removeFeatures(layerId: number): void {
    if (this.visibleClassifiers) {
      const index = this.visibleClassifiers.indexOf(layerId);
      if (index === -1) {
        return;
      }

      this.source.forEachFeature((f: olFeature) => {
        if (f.values_.classId !== layerId) {
          return;
        }
        this.source.removeFeature(f);
        delete this.loaded[f.id_];
      });
    }
  }

  protected addFeatureToSource(oFeature: olFeature, geomFeature: Feature) {
    oFeature.setId(geomFeature.id);
    this.source.addFeature(oFeature);
    const objData = JSON.parse(JSON.stringify(geomFeature));
    delete objData.geom;
    oFeature.setProperties(objData);
    this.setFeatureStyle(oFeature, objData);
    this.loaded[objData.id] = true;
  }

  private expandExtent(extent: number[]): number[] {
    const extentExpansionCoef = 0.1;
    const xDiff = extent[2] - extent[0];
    const yDiff = extent[3] - extent[1];

    extent[0] -= xDiff * extentExpansionCoef;
    extent[1] -= yDiff * extentExpansionCoef;
    extent[2] += xDiff * extentExpansionCoef;
    extent[3] += yDiff * extentExpansionCoef;

    return extent;
  }

  private createStyle(feature: olFeature, objData: any, geomStyle: GeomStyle) {
    let customStyle = null;
    if (this.type === 'Point') {

      if (geomStyle.customPointIcon) {
        const layerCustomIcon = objData['classId'] + '_' + geomStyle.customPointIcon;
        const customIconFromStorage = this.customIcons.find(i => i.url === layerCustomIcon);

        if (customIconFromStorage && this.currentCompanyId) {    //Get custom icon from local storage
          customStyle = new Style({
            image: new Icon({
              size: [40, 40],
              src: customIconFromStorage.base64Img
            })
          });
        }
        else if (this.currentCompanyId) {     //Get custom icon from directory
          this.profileFilesService.getCustomIcon(this.currentCompanyId, geomStyle.customPointIcon).subscribe(data => {
            this.createImageFromBlob(data, geomStyle.customPointIcon, objData['classId']);
            const createdImage = this.customIcons.find(i => i.url === layerCustomIcon);

            customStyle = new Style({
              image: new Icon({
                size: [40, 40],
                src: createdImage.base64Img
              })
            });

          }, (err) => {
            console.log(err);
          });
        }
        else {        //Return basic selected icon since unauthorized user can't access custom icons
          customStyle = this.createPointStyle(geomStyle);
        }
      }

      else {        //Get usual point style if no custom icon is selected for classifier
        customStyle = this.createPointStyle(geomStyle);
      }

    } else {
      customStyle = new Style({       //If object isn't "point" type
        fill: new Fill({
          color: geomStyle.fillColor
        }),
        stroke: new Stroke({
          color: geomStyle.lineColor,
          width: geomStyle.lineWidth,
        })
      });
      if (geomStyle.lineStyle === 'dashed') {
        customStyle.stroke_.lineDash_ = [8, 8];
      }
      //ol-ext polygon Fill Pattern
      if (geomStyle.fillPattern) {
        customStyle.fill_ = new FillPattern({
          pattern: geomStyle.fillPattern.toString(),
          color: geomStyle.fillPatternColor,
          fill: new Fill({ color: geomStyle.fillColor })
        });
      }
      if (geomStyle.strokePattern) {
        customStyle.stroke_ = new StrokePattern({
          pattern: geomStyle.strokePattern.toString(),
          color: geomStyle.linePatternColor,
          width: geomStyle.lineWidth,
          fill: new Fill({ color: geomStyle.lineColor })
        });
      }
    }

    if (geomStyle.labelEnabled && geomStyle.objLabel) {
      customStyle.text_ = new Text({
        text: objData.objectMapLabel,
        fill: new Fill({ color: geomStyle.labelColor }),
        font: 'bold ' + geomStyle.labelSize + 'px ' + geomStyle.labelFont
      });
      if (this.type === 'LineString') {
        customStyle.text_.placement_ = 'line';
      }
    }
    if (customStyle) {
      customStyle.setZIndex(objData.zIndex);
    }
    return customStyle;
  }

  createPointStyle(geomStyle: GeomStyle) {
    return new Style({
      text: new Text({
        text: geomStyle.pointIcon.toString(),
        fill: new Fill({ color: geomStyle.pointColor }),
        font: 'normal ' + (geomStyle.pointSize ? geomStyle.pointSize.toString() : '24') + 'px FontAwesome'
      }),
      image: new FontSymbol({
        form: geomStyle.pointForm ? geomStyle.pointForm.toString() : 'none',
        glyph: '',
        radius: +geomStyle.pointRadius || 15,
        rotation: +geomStyle.pointRotation,
        gradient: geomStyle.pointGradient,
        opacity: this.returnAlphaFromRgba(geomStyle.pointBgColor),
        fill: new Fill({ color: geomStyle.pointBgColor || 'rgba(255,255,0,1)' }),
      })
    });
  }

  private setFeatureStyle(feature: olFeature, objData: Feature) {
    const geomStyle = (objData.geomStyle);
    if (!geomStyle) {
      return;
    }

    if (this.selectedObj && this.selectedObj.id === objData.id) { // there is selected object that needs to set style
      feature.setStyle(new Style({
        fill: new Fill({
          color: '#ffffff40'
        }),
        stroke: new Stroke({
          color: '#000000',
          width: 5
        }),
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({ color: '#666666' }),
          stroke: new Stroke({ color: '#bada55', width: 1 })
        })
      }));
    }
    else {
      const customStyle = this.createStyle(feature, objData, geomStyle);
      feature.setStyle(customStyle);
    }
  }

  private hexToRgba(hex, opacity) {
    if (!hex) {
      return;
    }
    if (!opacity) {
      opacity = 1;
    }
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    return result;
  }

  private returnAlphaFromRgba(color) {
    if (!color || color.substring(0, 4) !== 'rgba') {
      return '1';
    }
    const temp = color.split(',');
    const alpha = temp[temp.length - 1].slice(0, -1);
    return alpha;
  }

  private createImageFromBlob(image: Blob, url: string, classId: number) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const layerCustomIcon = classId + '_' + url;
      if (!this.customIcons.some(c => c.url == layerCustomIcon)) {
        this.customIcons.push({
          url: layerCustomIcon,
          base64Img: reader.result
        });
        localStorage['customIcons_' + this.currentCompanyId] = JSON.stringify(this.customIcons);
      }
      this.clear();
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  ngOnDestroy(): void {
    this.mapObjectChangeSubscription.unsubscribe();
  }
}
