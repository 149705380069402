import { Injectable } from '@angular/core';
import { InfoDialogComponent } from '@pgis/shared/components/info-dialog/info-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class InfoDialogService {

  constructor(private modalService: NgbModal) {}

  showInfoDialog(msg: string, additionalData?: string[]): Promise<void> {
    const confirmDialog = this.modalService.open(InfoDialogComponent);
    confirmDialog.componentInstance.infoMsg = msg;
    confirmDialog.componentInstance.additionalData = additionalData;
    return confirmDialog.result.then(() => {
        return Promise.resolve();
      },
      (rejected) => {
        return Promise.resolve();
      });
  }
}
