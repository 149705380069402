import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommandResult, User } from '@pgis/shared/models';
import { Observable, Subject, EMPTY } from 'rxjs';
import { map, flatMap, share } from 'rxjs/operators';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  lastValidToken: string;
  reauthenticatedToken: Observable<string>;

  onReauthentication: Subject<any> = new Subject();

  constructor(private http: HttpClient) {
  }

  login(email: string, password: string): Promise<User> {
    return this.http.post<any>('/api/v1/token', { email: email, password: password })
    .pipe(
      map((commandResult: CommandResult) => {

        if (commandResult.data && commandResult.data.token) {
          localStorage.setItem('currentUser', JSON.stringify(commandResult.data));
        }
        this.onReauthentication.next();
        return commandResult.data;
      })).toPromise();
    }

  logout(): Observable<CommandResult> {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    if (!currentUser || !currentUser.token) {
      return EMPTY;
    }
    return this.http.post<any>('/api/v1/token/dispose', { accessToken: currentUser.token }).pipe(
      map((commandResult: CommandResult) => {
        localStorage.removeItem('currentUser');
        this.onReauthentication.next();
        return commandResult;
      }));
  }

  reauthenticateUser(accessToken: string): Observable<string> {
    if (this.lastValidToken === accessToken) {
      return this.reauthenticatedToken;
    }
    this.lastValidToken = accessToken;
    this.reauthenticatedToken = this.http.post<any>('/api/v1/token/refresh', { accessToken: accessToken }).pipe(
      flatMap((rez: CommandResult) => {
          if (!rez.data || !rez.data.refreshToken) {
            return null;
          }
          return this.http.post<any>('/api/v1/token/refreshed',
            { accessToken: accessToken, refreshToken: rez.data.refreshToken }).pipe(
            map((commandResult: CommandResult) => {
              if (commandResult.data && commandResult.data.token) {
                localStorage.setItem('currentUser', JSON.stringify(commandResult.data));
              }
              return commandResult.data.token;
            }),
            share());
        }),
       share());
    return this.reauthenticatedToken;
  }

  getCurrentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  userHavePermission(permission: string): boolean {
    const user = this.getCurrentUser();
    if (!user) {
      return false;
    }

    const unitPermissions = user.permissions.unitPermissions;

    return _.includes(_.flatten(_.values(unitPermissions)), permission);
  }

  resendPassword(email: string): Promise<any> {
    return this.http.post<any>('/api/v1/user/resend-password', { email: email }).toPromise();
  }
}
