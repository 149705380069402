import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { ObjectFilter } from '@pgis/shared/models/object-filter.model';

@Component({
  selector: 'pgis-object-filter',
  templateUrl: './object-filter.component.html',
  styleUrls: ['./object-filter.component.scss']
})
export class ObjectFilterComponent implements OnInit {

  @Input()
  objectFilter: ObjectFilter;

  @Input()
  layerFields: string[];

  @Output()
  onFilterChange: EventEmitter<ObjectFilter> = new EventEmitter<ObjectFilter>();

  layerFieldsOptions: { layerField: string }[];

  selectizeConfig: any = {
    labelField: 'layerField',
    valueField: 'layerField',
    dropdownDirection: 'down',
    highlight: true,
    addPrecedence: true,
    maxItems: 1,
    searchField: ['layerField'],
    plugins: ['dropdown_direction']
  };

  ngOnInit() {
    if (!this.objectFilter) {
      this.objectFilter = {
        field: '',
        value: ''
      };
    }
    this.layerFieldsOptions = this.layerFields.map(layerField => ({layerField}));
  }

  submitFilters(popover: NgbPopover) {
    this.onFilterChange.emit(this.objectFilter);
    popover.toggle();
  }

  resetFilters(popover: NgbPopover) {
    this.objectFilter = {
      field: '',
      value: ''
    };
    this.submitFilters(popover);
  }

  isFilterSet() {
    return this.objectFilter.field && this.objectFilter.value;
  }
}
