export class DrawObject {
  id: number;
  name: string;
  description?: string;
  oldClassId?: number;
  classId?: number;
  objectId?: number;
  isPublic?: boolean;
  data?: any;
  userEmail?: string;
  parsedData?: any;
}
