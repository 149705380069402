import { Injectable } from '@angular/core';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import olFeature from 'ol/Feature';
import olDraw from 'ol/interaction/Draw';
import WKT from 'ol/format/WKT.js';

import { LayerSource } from './layer-source';
import { GeometriesService } from '../geometries.service';
import { ObjectFilter } from '@pgis/shared/models/object-filter.model';
import { GeomsWithStyles } from '@pgis/shared/models/geoms-with-styles.model';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';

@Injectable({
  providedIn: 'root'
})
export class PointSourceService extends LayerSource {

  type: string = 'Point';
  source: VectorSource;
  loaded: {};
  objectFilter: ObjectFilter;

  constructor(private geometriesService: GeometriesService,
    profileFilesService: ProfileFilesService) {
    super(profileFilesService);
  }

  loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void> {
    return this.geometriesService.getPoints(extent, zoom, classifiersToLoad, this.loaded, this.objectFilter).then((geomFeatures: GeomsWithStyles) => {
      if (!geomFeatures.features) {
        return;
      }
      for (const geomFeature of geomFeatures.features) {
        geomFeature.geomStyle = geomFeatures.geomStyles[geomFeature.geomStyleIndex].geomStyle;
        const format = new WKT();
        const geom = format.readGeometryFromText(geomFeature.geom);

        const oFeature = new olFeature({
          geometry: geom,
          name: geomFeature.name
        });

        this.addFeatureToSource(oFeature, geomFeature);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getLayer(): VectorLayer {
    return new VectorLayer({
      source: this.source,
      renderBuffer: 1000,
    });
  }

  getDrawIntersection() {
    return new olDraw({
      type: 'Point',
      source: this.source,
    });
  }
}
