import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ProfileFilesService } from '@pgis/core';
import { GeomStyle, Classifier } from '@pgis/shared';

@Component({
  selector: 'pgis-object-legend',
  templateUrl: './object-legend.component.html',
  styleUrls: ['./object-legend.component.scss'],
  providers: [NgbPopover]
})
export class ObjectLegendComponent implements OnInit {

  @Input()
  objectData: Classifier;

  @Input()
  currentCompanyId: number;

  @ViewChild('popOver')
  popover: NgbPopover;

  position: string;

  protected customIcons: { url: string, base64Img: string | ArrayBuffer }[] = [];
  iconFromStorage: any;

  constructor(private profileFilesService: ProfileFilesService) { }

  async ngOnInit(): Promise<void> {
    const geomStyle = this.objectData.geomStyle;
    if(geomStyle != null) {
      if (this.currentCompanyId && localStorage['customIcons_' + this.currentCompanyId]) {
        this.customIcons = JSON.parse(localStorage['customIcons_' + this.currentCompanyId]);
      }
      if (geomStyle.customPointIcon) {
        this.loadCustomIcons(geomStyle);
      }

      if (!geomStyle.labelRules) {
        return;
      }
      if (geomStyle.labelRules[0]['rule'] != 'Default') {  //Setting Default style as first in the list
        const defaultRule = geomStyle.labelRules.find(gl => gl.rule == 'Default');
        geomStyle.labelRules = geomStyle.labelRules.filter(l => l.rule != 'Default');
        if(defaultRule) geomStyle.labelRules.unshift(defaultRule);
      }

      geomStyle.labelRules.forEach(ruleStyle => {
        this.loadCustomIcons(ruleStyle);
      });
    }
  }

  loadCustomIcons(ruleStyle){
    if(ruleStyle.customPointIcon) {   //Either load icon from server or get it from localStorage
      const layerCustomIcon = this.objectData['id'] + '_' + ruleStyle.customPointIcon;
      const iconFromStorage = this.customIcons.find(l => l.url == layerCustomIcon);
      
      if(iconFromStorage) {
        ruleStyle.customPointIconBase64 = iconFromStorage['base64Img'];
      }
      else if(this.currentCompanyId){
        this.profileFilesService.getCustomIcon(this.currentCompanyId, ruleStyle.customPointIcon).subscribe(data => {
          this.createImageFromBlob(data, ruleStyle);
        }, (err) => {
          console.log(err);
        });
      }
    }
  }

  showObjectLegend(geomStyle: GeomStyle) {
    if (!geomStyle) {
      return false;
    }
    return (geomStyle.labelRules && geomStyle.labelRules.length > 1) ? true : false;
  }

  closePopover(){
    if(this.popover && this.popover.isOpen()){
      this.popover.close();
    }
  }

  returnRuleName(name) {
    return name.includes('.') && name.includes('=') ? name.split('=').pop() : name;   //Return rule field value or 'Default' 
  }

  private createImageFromBlob(image: Blob, geomStyle: GeomStyle) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      geomStyle.customPointIconBase64 = reader.result;

      this.customIcons = localStorage['customIcons_' + this.currentCompanyId] ? JSON.parse(localStorage['customIcons_' + this.currentCompanyId]) : [];
      const layerCustomIcon = this.objectData.id + '_' + geomStyle.customPointIcon;

      if (!this.customIcons.some(c => c.url == layerCustomIcon)) {
        this.customIcons.push({
          url: layerCustomIcon,
          base64Img: reader.result
        });
        localStorage['customIcons_' + this.currentCompanyId] = JSON.stringify(this.customIcons);
      }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  returnLegendType(geomStyle) {
    if (!geomStyle) {
      return false;
    }
    else if (geomStyle.customPointIcon) {
      return 'custom';
    }
    else if(geomStyle.pointIcon && geomStyle.pointIcon !== '' 
      || geomStyle.pointColor && geomStyle.pointColor !== 'rgba(0,0,255,1)'
      || geomStyle.pointSize && geomStyle.pointSize !== 32
      || geomStyle.pointRadius && geomStyle.pointRadius !== 17
      || geomStyle.pointForm && geomStyle.pointForm !== 'none') {
      return 'point';
    }
    else if (geomStyle.lineColor && geomStyle.lineColor !== 'rgba(0,255,0,1)'
      || geomStyle.linePatternColor && geomStyle.linePatternColor !== 'rgba(255,0,255,1)'
      || geomStyle.lineWidth && geomStyle.lineWidth !== 1
      || geomStyle.lineStyle && geomStyle.lineStyle !== 'solid') {
      return 'line';
    }
    else if (geomStyle.fillColor) {
      return 'polygon';
    }
    else return false;
  }
}
