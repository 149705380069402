import { Injectable } from '@angular/core';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';

@Injectable({
  providedIn: 'root'
})
export class MapStylesService {

  dominantSpeacieStyle(feature, resalution) {
    const valdosaSuga = feature.getProperties().valdosaSuga;
    const vecums = feature.getProperties().valdosasSugasVecums;
    const transparency = 0.4;

    const style = new Style({
      fill: new Fill({
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      stroke: new Stroke({
        width: 1,
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      text: new Text({
        text: feature.getProperties().id.toString(),
        fill: new Fill({ color: '#000000' }),
        stroke: new Stroke({ color: '#FFFFFF', width: 1 }),
        font: 'bold 15px Verdana'
      })
    });

    switch (valdosaSuga) {
      case 1:
        {
          /*
          255	219	196
          255	184	138
          255	149	79
          255	114	21
          */
          if (vecums < 20) {
            style.setFill(new Fill({
              color: 'rgba( 255, 219, 196,' + transparency + ')'
            }));
          } else if (vecums >= 20 && vecums < 90) {
            style.setFill(new Fill({
              color: 'rgba( 255, 184, 138,' + transparency + ')'
            }));
          } else if (vecums >= 90 && vecums < 120) {
            style.setFill(new Fill({
              color: 'rgba( 255, 149, 79,' + transparency + ')'
            }));
          } else {
            style.setFill(new Fill({
              color: 'rgba(255, 114, 21,' + transparency + ')'
            }));
          }
          return style;
        }
      case 3:
        {
          /*
          240	199	240
          226	144	226
          211	88	211
          197	33	197
          */
          if (vecums < 20) {
            style.setFill(new Fill({
              color: 'rgba( 240, 199, 240,' + transparency + ')'
            }));
          } else if (vecums >= 20 && vecums < 90) {
            style.setFill(new Fill({
              color: 'rgba( 226, 144, 226,' + transparency + ')'
            }));
          } else if (vecums >= 90 && vecums < 120) {
            style.setFill(new Fill({
              color: 'rgba( 211, 88, 211,' + transparency + ')'
            }));
          } else {
            style.setFill(new Fill({
              color: 'rgba(197, 33, 197,' + transparency + ')'
            }));
          }
          return style;
        }
      case 4:
        {
          /*
          191	255	255
          127	255	255
          63	255	255
          0	255	255
          */
          if (vecums < 20) {
            style.setFill(new Fill({
              color: 'rgba( 191, 255, 255,' + transparency + ')'
            }));
          } else if (vecums >= 20 && vecums < 50) {
            style.setFill(new Fill({
              color: 'rgba( 127, 255, 255,' + transparency + ')'
            }));
          } else if (vecums >= 50 && vecums < 70) {
            style.setFill(new Fill({
              color: 'rgba( 63, 255, 255,' + transparency + ')'
            }));
          } else {
            style.setFill(new Fill({
              color: 'rgba(0, 255, 255,' + transparency + ')'
            }));
          }
          return style;
        }
      case 6:
        {
          /*
          227	194	255
          198	155	255
          169	125	255
          128	85	255
          */
          if (vecums < 20) {
            style.setFill(new Fill({
              color: 'rgba( 227, 194, 255,' + transparency + ')'
            }));
          } else if (vecums >= 20 && vecums < 50) {
            style.setFill(new Fill({
              color: 'rgba( 198, 155, 255,' + transparency + ')'
            }));
          } else if (vecums >= 50 && vecums < 70) {
            style.setFill(new Fill({
              color: 'rgba( 169, 125, 255,' + transparency + ')'
            }));
          } else {
            style.setFill(new Fill({
              color: 'rgba( 128, 85, 255,' + transparency + ')'
            }));
          }
          return style;
        }
      case 8:
        style.setFill(new Fill({
          color: 'rgba( 63, 255, 63,' + transparency + ')'
        }));
        return style;
      case 9:
        style.setFill(new Fill({
          color: 'rgba( 123, 123, 0,' + transparency + ')'
        }));
        return style;
      case 10:
        style.setFill(new Fill({
          color: 'rgba( 112, 112, 112,' + transparency + ')'
        }));
        return style;
      case 11:
        style.setFill(new Fill({
          color: 'rgba( 130, 192, 210,' + transparency + ')'
        }));
        return style;
      case 12:
        style.setFill(new Fill({
          color: 'rgba( 255, 255, 63,' + transparency + ')'
        }));
        return style;
      default:
        {
          if (valdosaSuga > 0 && valdosaSuga < 50) {
            style.setFill(new Fill({
              color: 'rgba(186, 221, 221,' + transparency + ')'
            }));
          } else {
            style.setFill(new Fill({
              color: 'rgba( 100,100,100,' + transparency + ')'
            }));
          }
        }
        return style;
    }
  }

  volumeStyle(feature, resalution) {
    const transparency = 0.8;
    const style = new Style({
      fill: new Fill({
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      stroke: new Stroke({
        width: 1,
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      text: new Text({
        text: feature.getProperties().id.toString(),
        fill: new Fill({ color: '#000000' }),
        stroke: new Stroke({ color: '#FFFFFF', width: 1 }),
        font: 'bold 15px Verdana'
      })
    });
    const kraja = feature.getProperties().krajaUzHa;
    switch (kraja) {
      /*
219,115,18 #6
219,167,23 #5
219,206,23 #4
184,219,28 #3
120,219,33 #2
4,171,7 #1
3,130,5 #0
       */
      case 0:
        style.setFill(new Fill({
          color: 'rgba( 3, 144, 3,' + transparency + ')'
        }));
        return style;
      case 1:
        style.setFill(new Fill({
          color: 'rgba( 4, 185, 7,' + transparency + ')'
        }));
        return style;
      case 2:
        style.setFill(new Fill({
          color: 'rgba( 111, 203, 30,' + transparency + ')'
        }));
        return style;
      case 3:
        style.setFill(new Fill({
          color: 'rgba( 171, 219, 38,' + transparency + ')'
        }));
        return style;
      case 4:
        style.setFill(new Fill({
          color: 'rgba( 219, 206, 23,' + transparency + ')'
        }));
        return style;
      case 5:
        style.setFill(new Fill({
          color: 'rgba(219, 167, 23,' + transparency + ')'
        }));
        return style;
      case 6:
        style.setFill(new Fill({
          color: 'rgba( 219, 129, 10,' + transparency + ')'
        }));
        return style;
      default:
        style.setFill(new Fill({
          color: 'rgba(100, 100, 100,' + transparency + ')'
        }));
        return style;
    }


  }

  growthConditionsTypeStyle(feature, resalution) {

    const aat = feature.getProperties().aat;
    const cl = 250 / 30 * aat;
    const transparency = 0.8;
    const style = new Style({
      fill: new Fill({
        color: 'rgba(0,' + cl + ',' + (250 - cl) + ',' + transparency + ')'
      }),
      stroke: new Stroke({
        width: 1,
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      text: new Text({
        text: feature.getProperties().id.toString(),
        fill: new Fill({ color: '#000000' }),
        stroke: new Stroke({ color: '#FFFFFF', width: 1 }),
        font: 'bold 15px Verdana'
      })
    });

    switch (aat) {
      case 1:
        style.setFill(new Fill({
          color: 'rgba( 255, 255, 183,' + transparency + ')'
        }));
        break;
      case 2:
        style.setFill(new Fill({
          color: 'rgba( 255, 255, 0,' + transparency + ')'
        }));
        break;
      case 3:
        style.setFill(new Fill({
          color: 'rgba( 235, 235, 0,' + transparency + ')'
        }));
        break;
      case 4:
        style.setFill(new Fill({
          color: 'rgba( 255, 222, 117,' + transparency + ')'
        }));
        break;
      case 5:
        style.setFill(new Fill({
          color: 'rgba( 255, 192, 0,' + transparency + ')'
        }));
        break;
      case 6:
        style.setFill(new Fill({
          color: 'rgba( 242, 153, 34,' + transparency + ')'
        }));
        break;
      case 7:
        style.setFill(new Fill({
          color: 'rgba( 88, 238, 117,' + transparency + ')'
        }));
        break;
      case 8:
        style.setFill(new Fill({
          color: 'rgba( 0, 204, 0,' + transparency + ')'
        }));
        break;
      case 9:
        style.setFill(new Fill({
          color: 'rgba( 0, 176, 80,' + transparency + ')'
        }));
        break;
      case 10:
        style.setFill(new Fill({
          color: 'rgba( 0, 130, 59,' + transparency + ')'
        }));
        break;
      case 11:
        style.setFill(new Fill({
          color: 'rgba( 0, 102, 0,' + transparency + ')'
        }));
        break;
      case 12:
        style.setFill(new Fill({
          color: 'rgba( 51, 51, 255,' + transparency + ')'
        }));
        break;
      case 14:
        style.setFill(new Fill({
          color: 'rgba( 0, 112, 192,' + transparency + ')'
        }));
        break;
      case 15:
        style.setFill(new Fill({
          color: 'rgba( 32, 179, 214,' + transparency + ')'
        }));
        break;
      case 16:
        style.setFill(new Fill({
          color: 'rgba( 0, 223, 218,' + transparency + ')'
        }));
        break;
      case 17:
        style.setFill(new Fill({
          color: 'rgba( 246, 178, 164,' + transparency + ')'
        }));
        break;
      case 18:
        style.setFill(new Fill({
          color: 'rgba( 255, 124, 128,' + transparency + ')'
        }));
        break;
      case 19:
        style.setFill(new Fill({
          color: 'rgba( 255, 0, 0,' + transparency + ')'
        }));
        break;
      case 21:
        style.setFill(new Fill({
          color: 'rgba( 192, 0, 0,' + transparency + ')'
        }));
        break;
      case 22:
        style.setFill(new Fill({
          color: 'rgba( 197, 190, 151,' + transparency + ')'
        }));
        break;
      case 23:
        style.setFill(new Fill({
          color: 'rgba(, 148, 139, 84,' + transparency + ')'
        }));
        break;
      case 24:
        style.setFill(new Fill({
          color: 'rgba( 128, 128, 128,' + transparency + ')'
        }));
        break;
      case 25:
        style.setFill(new Fill({
          color: 'rgba( 90, 90, 90,' + transparency + ')'
        }));
        break;
      default:
        style.setFill(new Fill({
          color: 'rgba(0,' + cl + ',' + (250 - cl) + ',' + transparency + ')'
        }));
        return style;
    }
    return style;

  }

  bonityStyle(feature, resalution) {
    const bon = feature.getProperties().bon;

    const transparency = 0.8;
    const style = new Style({
      fill: new Fill({
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      stroke: new Stroke({
        width: 1,
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      text: new Text({
        text: feature.getProperties().id.toString(),
        fill: new Fill({ color: '#000000' }),
        stroke: new Stroke({ color: '#FFFFFF', width: 1 }),
        font: 'bold 15px Verdana'
      })
    });

    switch (bon) {
      /*
219,115,18 #6
219,167,23 #5
219,206,23 #4
184,219,28 #3
120,219,33 #2
4,171,7 #1
3,130,5 #0
       */
      case 0:
        style.setFill(new Fill({
          color: 'rgba(  3, 144, 3,' + transparency + ')'
        }));
        return style;
      case 1:
        style.setFill(new Fill({
          color: 'rgba(  4, 185, 7,' + transparency + ')'
        }));
        return style;
      case 2:
        style.setFill(new Fill({
          color: 'rgba(  111, 203, 30,' + transparency + ')'
        }));
        return style;
      case 3:
        style.setFill(new Fill({
          color: 'rgba(  171, 219, 38,' + transparency + ')'
        }));
        return style;
      case 4:
        style.setFill(new Fill({
          color: 'rgba( 219, 206, 23,' + transparency + ')'
        }));
        return style;
      case 5:
        style.setFill(new Fill({
          color: 'rgba(  219, 167, 23,' + transparency + ')'
        }));
        return style;
      case 6:
        style.setFill(new Fill({
          color: 'rgba(  219, 129, 10,' + transparency + ')'
        }));
        return style;
      default:
        style.setFill(new Fill({
          color: 'rgba(  100, 100, 100,' + transparency + ')'
        }));
        return style;
    }
  }

  contoursOnly(feature) {
    const transparency = 0.8;
    return new Style({
      fill: new Fill({
        color: 'rgba(0,0,0,0)'
      }),
      stroke: new Stroke({
        width: 1,
        color: 'rgba(0,0,0,' + transparency + ')'
      }),
      text: new Text({
        text: feature.getProperties().id.toString(),
        fill: new Fill({ color: '#000000' }),
        stroke: new Stroke({ color: '#FFFFFF', width: 1 }),
        font: 'bold 15px Verdana'
      })
    });
  }
}
