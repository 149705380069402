import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { GeometriesService } from '@pgis/core/services/geometries.service';
import { GeometryFilesService } from '@pgis/core/services/geometry-files.service';
import { Feature } from '@pgis/shared/models/feature.model';
import { ImageEnlargeService } from '@pgis/core/services/image-enlarge.service';

@Component({
  selector: 'pgis-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupComponent implements OnInit {

  @Input()
  props: any;

  fullData: Feature;
  isPublicMode: boolean;
  imageArray: string[];

  constructor(private geometriesService: GeometriesService,
    private geometryFilesService: GeometryFilesService,
    private changeDetectorRef: ChangeDetectorRef,
    private imageEnlargeService: ImageEnlargeService) {
  }

  async ngOnInit(): Promise<void> {
    this.fullData = await this.geometriesService.getGeometryData(this.props.id);
    // try {
    //   this.fullData.parsedData = JSON.parse(this.fullData.data);
    // } catch (e) {
    //   this.fullData.parsedData = this.fullData.data;
    // }
    this.imageArray = await this.geometryFilesService.getUploadedImages(this.props.id);
    this.changeDetectorRef.detectChanges();
  }

  async enlargeImage(imageName: string): Promise<void> {
    const fullImage = await this.geometryFilesService.getUploadedImageFull(this.props.id, imageName);
    this.imageEnlargeService.enlarge(fullImage);
    this.changeDetectorRef.detectChanges();
  }
}
