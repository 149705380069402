import { Component, Input, OnInit } from '@angular/core';
import { GeomStyle, Classifier } from '@pgis/shared';

@Component({
  selector: 'pgis-print-legend',
  templateUrl: './print-legend.component.html',
  styleUrls: ['./print-legend.component.scss']
})
export class PrintLegendComponent implements OnInit {

  @Input()
  objectData: Classifier[];

  constructor() { }

  ngOnInit() {
    for(const feature of this.objectData) {
      if (feature.geomStyle.labelRules && feature.geomStyle.labelRules[0]['rule'] !== 'Default') {  //Setting Default style as first in the list
        const defaultRule = feature.geomStyle.labelRules.find(gl => gl.rule === 'Default');
        feature.geomStyle.labelRules = feature.geomStyle.labelRules.filter(l => l.rule !== 'Default');
        if(defaultRule) feature.geomStyle.labelRules.unshift(defaultRule);
      }
    }
  }

  showObjectLegend(geomStyle: GeomStyle) {
    if (!geomStyle) {
      return false;
    }
    return (geomStyle.labelRules && geomStyle.labelRules.length > 1) ? true : false;
  }

  returnRuleName(name) {
    return name.includes('.') && name.includes('=') ? name.split('=').pop() : name;   //Return rule field value or 'Default' 
  }

  returnLegendType(geomStyle) {
    if (!geomStyle) {
      return false;
    }
    else if (geomStyle.customPointIcon) {
      return 'custom';
    }
    else if (geomStyle.lineColor && geomStyle.lineColor !== 'rgba(0,255,0,1)'
      || geomStyle.linePatternColor && geomStyle.linePatternColor !== 'rgba(255,0,255,1)'
      || geomStyle.lineWidth && geomStyle.lineWidth !== 1
      || geomStyle.lineStyle && geomStyle.lineStyle !== 'solid') {
      return 'line';
    }
    else if (geomStyle.fillColor && geomStyle.fillColor != 'rgba(255,0,0,1)'
      || geomStyle.fillPatternColor && geomStyle.fillPatternColor !== 'rgba(0,255,255,1)'
      || geomStyle.fillPattern != null && geomStyle.fillPattern !== '') {
      return 'polygon';
    }
    else if (geomStyle.pointIcon) {
      return 'point';
    }
    else return false;
  }
}
