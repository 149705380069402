export class Log {
  public id: number;
  public date: string;
  public level: string;
  public messsage: string;
  public exception: string;
  public browser: string;
  public userName: string;
  public hostAddress: string;
  public url: string;
  public requestId: string;
  public userId: number;
  public eventId: number;
}
export interface LogFilter {
  severity: string;
  eventFilters: EventFilter[];
}

export interface EventFilter {
  eventId: number;
  fieldName: string;
  selected: boolean;
}
