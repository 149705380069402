import { Injectable } from '@angular/core';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { Circle as CircleStyle } from 'ol/style.js';

@Injectable({ providedIn: 'root' })
export class MapGeolocationService {

  VZDSearchLayer: VectorLayer;
  map: Map;

  setMap(map: Map): void {
    this.map = map;
  }

  setVZDPoint(coordinates, enableState): void {
    if (!enableState) {
      this.map.removeLayer(this.VZDSearchLayer);
    }
    else {
      const VZDPositionFeature = new Feature();
      VZDPositionFeature.setStyle(new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#33cc33'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          })
        })
      }));
      VZDPositionFeature.setGeometry(new Point(coordinates));
      this.VZDSearchLayer = new VectorLayer({
        source: new VectorSource({
          features: [VZDPositionFeature]
        })
      });
      this.map.addLayer(this.VZDSearchLayer);
    }
  }
}
