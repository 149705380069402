import { RuleSet } from 'angular2-query-builder';
import { LayerField } from './layer-field.model';

export class Classifier {

  id: number;
  id1: number;
  name: string;
  description: string;
  checked?: boolean;
  info: string;
  geomStyle: GeomStyle;
  layerFields: LayerField[];
  selected: boolean;
  isDefault: boolean;
  parentName: string;
  parentDescription: string;
  childClassifiers: Classifier[];
  serverside: boolean;
  ruleset: RuleSet;
  zoomFrom: number;
  zoomTo: number;
  zIndex: number;
  rasterLayer: boolean;
  imageArchive: string;
  imageArchiveObj: object;
  allObjectsPublic: boolean;
  allowPublicRegistration: boolean;
  showDefault: boolean;
}

export class GeomStyle {
  fillColor: string = 'rgba(255,0,0,1)';
  lineColor: string = 'rgba(0,255,0,1)';
  pointColor: string = 'rgba(0,0,255,1)';
  pointBgColor: string = 'rgba(255,255,0,1)';
  fillPatternColor: string = 'rgba(0,255,255,1)';
  linePatternColor: string = 'rgba(255,0,255,1)';
  labelSize: number = 15;
  labelColor: string = 'rgba(0,0,0,1)';
  labelFont: string = 'Arial';
  labelEnabled: boolean = true;
  lineWidth: number = 1;
  lineStyle: string = 'solid';
  objLabel: string = '';
  rule: string = '';
  pointIcon: string = '';
  pointSize: number = 32;
  pointForm: string = 'none';
  pointRadius: number = 17;
  pointRotation: number = 0;
  pointGradient: boolean = false;
  customPointIcon: string = '';
  customPointIconBase64: string | ArrayBuffer = '';
  fillPattern: string = '';
  strokePattern: string = '';
  labelRules: any = [];
  minResolution: number = 200;
  maxResolution: number = 2000;
}
